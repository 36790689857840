import { Box, Container, Typography } from "@mui/material";
import React from "react";
import ServicesGrid from "./ServicesGrid";

const ServicesOverviewSection = ({ pageName = "" }) => {
  return (
    <Box sx={{ py: { xs: 4, sm: 4 }, backgroundColor: "white" }}>
      <Container maxWidth="md">
        <Typography
          variant="h2"
          align="center"
          sx={{ mb: { xs: 2, sm: 4 }, fontSize: { xs: "42px", sm: "48px" } }}
        >
          Your Household Helper
        </Typography>
        <Typography sx={{ mb: { xs: 2, sm: 2 } }}>
          We are just a call or click away from providing excellent household
          help. For the tasks you don't want to or can't do anymore, we have you
          covered. We're reliable, easy to schedule, and get the job done!
        </Typography>
        <ServicesGrid expanded={false} pageName={pageName} />
      </Container>
    </Box>
  );
};

export default ServicesOverviewSection;
