import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";

import { collection, onSnapshot, query, where } from "firebase/firestore";

import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ConfirmationBurst } from "../../animations/ConfirmationBurst";
import FooterSimple from "../../components/FooterSimple";
import RescheduleVisitPublic from "../../components/RescheduleVisitPublic";
import ResponsiveDialog from "../../components/dialogs/ResponsiveDialog";
import VisitSummary from "../../components/VisitSummary";
import RescheduleProvider from "../../providers/RescheduleProvider";
import { SnackbarProvider } from "../../providers/SnackbarProvider";
import { rescheduleVisit } from "../../services/memberServices";

const ReschedulePublicPage = () => {
  const [visit, setVisit] = useState(null); // Define visit state
  const [error, setError] = useState("");
  const params = new URLSearchParams(window.location.search);
  const ref = params.get("ref");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const Screen = {
    FETCHING: "fetching",
    SCHEDULER: "scheduler",
    SENDING: "sending",
    CONFIRMATION: "confirmation",
  };

  const [currentScreen, setCurrentScreen] = useState(Screen.FETCHING);

  useEffect(() => {
    // Fetching data logic
    const q = query(collection(db, "visits"), where("ref", "==", ref));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const visits = snapshot.docs.map((doc) => ({
        id: doc.id, // Store the document ID
        ...doc.data(), // Spread the rest of the document data
      }));

      if (visits.length > 0) {
        const visitData = visits[0]; // Get the first visit data along with its ID
        setVisit(visitData);
        // Determine which screen to show based on visit status
        setCurrentScreen(
          visitData.status === "confirmed"
            ? Screen.CONFIRMATION
            : Screen.SCHEDULER
        );
      } else {
        setCurrentScreen(Screen.NO_VISITS_FOUND); // Set to NO_VISITS_FOUND if no visits are found
      }
    });

    return () => unsubscribe();
  }, [ref, db]); // Include db in the dependencies array if it's a state or prop

  const handleSubmit = async (values) => {
    setCurrentScreen(Screen.SENDING); // Move to sending info screen
    await rescheduleVisit(values);
    setCurrentScreen(Screen.CONFIRMATION); // Move to confirmation screen
  };

  return (
    <SnackbarProvider>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          bgcolor: "white",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-end",
          position: "relative",
          px: 2,
        }}
      >
        <ResponsiveDialog
          open={true}
          onClose={() => {}}
          fullHeight={isMobile ? null : "calc(100vh - 50px)"}
          title={`Reschedule`}
          width="800px"
          showAction={false}
          showBackdrop={false}
          maxHeight="583px"
          anchor="right"
          showLogo={true}
        >
          {currentScreen === Screen.FETCHING && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Ensure it spans the full width
                gap: 2,
                minHeight: "300px",
                marginY: 2,
              }}
            >
              <CircularProgress size={48} color="inherit" />
              {/* Additional content */}
            </Box>
          )}
          {currentScreen === Screen.NO_VISITS_FOUND && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                minHeight: "300px",
                marginY: 2,
              }}
            >
              <Typography variant="body1" align="center">
                This series has already been rescheduled.
              </Typography>
            </Box>
          )}
          {currentScreen === Screen.SCHEDULER && (
            <RescheduleProvider visit={visit}>
              <RescheduleVisitPublic onSubmit={handleSubmit} />
            </RescheduleProvider>
          )}
          {currentScreen === Screen.SENDING && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Ensure it spans the full width
                gap: 2,
                minHeight: "300px",
                marginY: 2,
              }}
            >
              <CircularProgress size={48} color="inherit" />
              <Typography variant="body1" align="center" sx={{ pt: 1 }}>
                This may take several seconds.
              </Typography>
            </Box>
          )}
          {currentScreen === Screen.CONFIRMATION && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 2,
                  minHeight: "300px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%", // Ensure it spans the full width
                    gap: 2,
                    marginY: 2,
                    maxWidth: "400px",
                    width: "auto",
                  }}
                >
                  <ConfirmationBurst size="xlarge" />
                  <Typography variant="h6" marginTop={2}>
                    Confirmed!
                  </Typography>
                  <Typography>Your visit has been rescheduled.</Typography>
                  <Box
                    sx={{
                      backgroundColor: "#F5F5F5", // Light gray background
                      borderRadius: "15px",
                      px: 3,
                      py: 1,
                    }}
                  >
                    <VisitSummary
                      timeZoneId={visit.timeZoneId}
                      start={visit.start}
                      end={visit.end}
                      employees={visit.employees}
                    />
                  </Box>
                </Box>
              </Box>
              <DialogActions
                sx={{
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => navigate("/dashboard")}
                  sx={{
                    textTransform: "none",
                    minWidth: { xs: "100%", sm: "200px" },
                    height: { xs: "60px", sm: "auto" },
                  }}
                  disableElevation={true}
                  variant="contained"
                  fullWidth
                >
                  Go to Dashboard
                </Button>
              </DialogActions>
            </>
          )}
        </ResponsiveDialog>
        {!isMobile && <FooterSimple />}
      </Box>
    </SnackbarProvider>
  );
};

export default ReschedulePublicPage;
