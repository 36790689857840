import {
  AccountBalance,
  Checklist,
  DoubleArrow,
  Grading,
  HomeRepairServiceRounded,
  Loyalty,
  PersonPin,
  Verified,
} from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event"; // For "Link Visits"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

function MembershipDetails({ pageName = "" }) {
  const ref = useTrackVisibility(() => {}, `${pageName}/"membership details"`); // Pass the logMessage here

  const theme = useTheme();

  // Array of objects for each card
  const cardDetails = [
    {
      title: "Simple Scheduling",
      icon: (
        <EventIcon
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "Choose the day and time that works best for you and book online or over the phone, days to weeks in advance. Enjoy direct access to our calendar. ",
    },
    {
      title: "Pick Your Helper",
      icon: (
        <PersonPin
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "You have full control of who helps you. Browse profiles, read reivews, and develop friendships with your favorite helpers!",
    },
    {
      title: "Fits Your Budget",
      icon: (
        <Loyalty sx={{ color: theme.palette.primary.dark }} fontSize="large" />
      ),
      body: "Regardless of the task, we charge $40/hour with a one hour minimum. Most members book 2 hours a month, some more or less depending on budget and needs.", //  to have us complete their to-do list.",
    },
    {
      title: "All Your Needs",
      icon: (
        <Checklist
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "Our members love our versatility. In a 1-hour visit, we might move heavy boxes down from storage, fix a computer problem, and help weed your flower beds.",
    },

    // {
    //   title: "$80/month Average",
    //   icon: (
    //     <Loyalty sx={{ color: theme.palette.primary.dark }} fontSize="large" />
    //   ),
    //   body: "Our members book 2 hours per month on average, depending on budget and needs. We can accomodate anywhere from weekly help, to here-and-there.",
    // },

    {
      title: "No Monthly Fees",
      icon: (
        <Grading sx={{ color: theme.palette.primary.dark }} fontSize="large" />
      ),
      // body: "There are no contracts or monthly fees. We're here to help as needed, whether that's once every season or multiple times a week! Your lifetime membership is just a one time payment to cover our administrative costs.",
      body: "There are no contracts, monthly fees, or minimums. Instead, our $50 signup fee grants you lifetime access. Our 300+ members love the peace of mind we provide.", //that comes with our excellent help on-demand.",
      // body: "There are no contracts or monthly fees, just our flat hourly rate. Our members love the peace of mind that comes with our help on-demand.",
    },
    {
      title: "Easy Payment",
      icon: (
        <AccountBalance
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      // body: "After your first visit, we'll email you an invoice to set up your payment account. You can pay with a debit or credit card, and you can change your payment method at any time.",
      body: "The day after your visit, we'll bill your preferred payment method or email an invoice. No need to pay helpers directly, but tips are loved for great work.",
    },
    {
      title: "Tools and Supplies",
      icon: (
        <HomeRepairServiceRounded
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "Please be prepared with any tools and supplies, whether it's a ladder, shovel, or mop. This helps us work efficiently and provide a wide range of services.",
    },
    {
      title: "Fully Covered",
      icon: (
        <Verified sx={{ color: theme.palette.primary.dark }} fontSize="large" />
      ),
      body: "Every visit is fully covered. We are licensed, insured, and BBB accredited, ensuring you receive reliable and excellent service every time.",
    },
  ];

  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Box
        sx={{
          paddingY: { xs: 4, sm: 4 },
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "auto", // Centers the box while maintaining a max width
          maxWidth: 800, // Max width of the text box
          paddingX: theme.spacing(2), // Horizontal padding
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          align="left"
          color="white"
          sx={{ mt: 4 }}
        >
          {/* Service Details */}
          Pricing and Benefits
        </Typography>
        <Typography
          variant="body1"
          fontWeight="400"
          mb={3}
          align="left"
          color="white"
        >
          Our over 300+ members enjoy excellent help around the house. From the
          occasional odd-job to routine chores, we have you covered. We look
          forward to getting to know you!
          {/* And with our satisfaction guarantee, you can
          experience the joys of a Linked Lives helper for yourself, whenever
          you are ready to schedule your first visit! */}
        </Typography>

        <Grid
          container
          spacing={2}
          alignItems="stretch"
          justifyContent="center"
          display="flex"
        >
          {cardDetails.map((detail, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.primary.lightest,
                  display: "flex",
                  flexDirection: "column",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition for transform and shadow
                  "&:hover": {
                    transform: "translateY(-4px)", // Slightly raise the card
                    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)", // Elegant drop shadow
                  },
                  height: "100%", // Ensure card takes full height of the grid item
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <ListItemIcon>{detail.icon}</ListItemIcon>
                  <Typography variant="h6">{detail.title}</Typography>
                  <Typography variant="body2" mt={1}>
                    {detail.body}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          size="large"
          color="success"
          disableElevation
          component={Link} // use the Link component as the root node of the Button
          to="/get-started" // use 'to' instead of 'href' for react-router's Link
          sx={{
            width: { xs: "100%", sm: "400px" },
            height: "60px",
            textTransform: "none",
            mx: "auto",
            mt: 4,

            position: "relative", // Needed for the overlay
            overflow: "hidden", // Ensures the effect stays within the button boundaries
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: "-150%", // Start off the button
              width: "100%", // Make it wide enough to cover the button
              height: "100%",
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
              transform: "skewX(-20deg)", // Slight skew to angle the wipe
              animation: "wipe 2.5s infinite", // Smooth, infinite animation
            },
            "@keyframes wipe": {
              "0%": { left: "-200%" },
              "100%": { left: "200%" }, // Move it fully across
            },
          }}
          startIcon={<DoubleArrow />}
        >
          Get started in under 5 minutes
        </Button>
      </Box>
    </Box>
  );
}

export default MembershipDetails;
