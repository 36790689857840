import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useReschedule } from "../providers/RescheduleProvider";
import { calculateVisits } from "../services/memberServices";
import VisitPicker from "./scheduler/VisitPicker";

const RescheduleVisitPublic = ({
  onSubmit,
  showTitle = true,
  initialFrequency = null,
}) => {
  const { visitData, loading } = useReschedule();
  const [selectedVisit, setSelectedVisit] = useState({});
  const [filters, setFilters] = useState({
    duration: 2,
    frequency: 0,
    employeeIds: [],
    numEmployees: "1",
  });

  // pre-populate with characteristics from the past visit
  useEffect(() => {
    console.log("visitData?.visit?.end: ", visitData?.visit?.end.toDate());

    let duration = 2;

    if (visitData?.visit?.start && visitData?.visit?.end) {
      duration = moment(visitData?.visit?.end?.toDate()).diff(
        moment(visitData?.visit?.start?.toDate()),
        "hours",
        true
      );
    }

    console.log("duration: ", duration);

    setFilters((prevFilters) => {
      // Determine the frequency
      const frequency =
        initialFrequency !== null
          ? initialFrequency
          : visitData?.visit?.seriesEmployeesNeeded > 0
          ? visitData?.visit?.recurrence.frequency
          : 0;

      return {
        ...prevFilters,
        duration,
        frequency,
      };
    });
  }, [visitData?.visit]);

  const params = new URLSearchParams(window.location.search);
  const key = params.get("key") || "key_123";

  console.log("=====> selectedVisit: ", selectedVisit);

  const handleSubmit = () => {
    console.log("submitting 1");

    // Map employee IDs to their corresponding objects
    const selectedEmployees = selectedVisit.visit.employeeIds.reduce(
      (acc, id) => {
        const employee = visitData.employees[id];
        if (employee) {
          acc[id] = employee;
        }
        return acc;
      },
      {}
    );

    onSubmit({
      visitId: visitData?.visit?.id,
      key,
      timeZoneId: visitData?.visit?.timeZoneId,
      startTimestamp: selectedVisit.visit.start,
      endTimestamp: selectedVisit.visit.end,
      frequency: filters.frequency,
      employees: selectedEmployees,
    });
  };

  // Necessary to to keep a global concept of visits even when component is not mounted
  const [visits, setVisits] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // Corrected Object.keys usage
      if (
        visitData?.employeeAvailability &&
        Object.keys(visitData?.employeeAvailability).length > 0 &&
        visitData?.visit?.timeZoneId
      ) {
        const newVisits = await calculateVisits(
          visitData?.employeeAvailability,
          visitData?.visit?.timeZoneId
        );
        setVisits(newVisits);
      }
    };

    fetchData();
  }, [visitData?.employeeAvailability, visitData?.visit?.timeZoneId]); // timeZone

  console.log("visitData");

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            position: "sticky",
            top: { xs: "20vh", sm: undefined },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%", // Ensure it spans the full width
            gap: 2,
            marginY: 2,
          }}
        >
          <CircularProgress size={48} color="inherit" />
          {/* Additional content */}
        </Box>
      ) : (
        <>
          <DialogContent>
            <VisitPicker
              setVisits={setVisits}
              visits={visits}
              selectedVisit={selectedVisit}
              setSelectedVisit={setSelectedVisit}
              filters={filters}
              setFilters={setFilters}
              employees={visitData?.employeeAvailability}
              timeZoneId={visitData?.visit?.timeZoneId}
              memberId={visitData?.visit?.member}
              bestMatchEmployee={null}
            />
          </DialogContent>

          {/* Use AppBar for mobile view */}
          <DialogActions
            sx={{
              zIndex: 10,
              justifyContent: "center",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Button
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                width: { xs: "100%", sm: "200px" },
                height: { xs: "60px", sm: "56px" },
              }}
              disableElevation={true}
              variant="contained"
              disabled={Object.keys(selectedVisit).length === 0}
              fullWidth
            >
              Confirm Reschedule
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default RescheduleVisitPublic;
