import { Box } from "@mui/material";
import React from "react";

import bbbBadge from "../assets/AB-seal-horz.svg";
import facebookReviews from "../assets/Facebook-reviews.webp";
import googleMyBusiness from "../assets/Google-My-Business.webp";
import googleReviews from "../assets/Google-Reviews.png";
import familyOwnedBadge from "../assets/family-owned.png";
import licensedInsured from "../assets/licensed-insured.png";
import useTrackVisibility from "../hooks/useTrackVisibility";

const CredibilityGrid = ({ size = "normal", noReviews = false, pageName = "" }) => {

  const ref = useTrackVisibility(() => {},
  `${pageName}/"credibility grid"`); // Pass the logMessage here

  const images = [
    {
      src: bbbBadge,
      link: "https://www.bbb.org/us/wa/anacortes/profile/home-services/linked-lives-1296-1000171080",
    },
    { src: familyOwnedBadge, link: "https://www.ourlinkedlives.com/about" },
    { src: licensedInsured, link: null },
    ...(noReviews
      ? []
      : [
          {
            src: googleReviews,
            link: "https://maps.app.goo.gl/fr6atTnzme5J335M6",
          },
          {
            src: googleMyBusiness,
            link: "https://maps.app.goo.gl/fr6atTnzme5J335M6",
          },
          {
            src: facebookReviews,
            link: "https://www.facebook.com/ourLinkedLives",
          },
        ]),
  ];

  return (
    <Box
    ref={ref}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: { xs: 2, sm: 4 },
        textAlign: "center",
        alignItems: "center",
        my: 4,
      }}
    >
      {images.map((image, index) => (
        <Box
          key={index}
          component={image.link ? "a" : "div"}
          href={image.link || undefined}
          target={image.link ? "_blank" : undefined}
          rel={image.link ? "noopener noreferrer" : undefined}
          sx={{
            display: "block",
            width:
              size === "large" ? "200px" : size === "small" ? "100px" : "150px",
            height: "auto",
          }}
        >
          <Box
            component="img"
            src={image.src}
            alt={`Badge ${index}`}
            sx={{ width: "100%", height: "auto", mx: "auto" }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CredibilityGrid;
