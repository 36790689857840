import TextField from "@mui/material/TextField";
import React from "react";

const PhoneField = ({
  phone,
  error,
  handleChange,
  disabled = false,
  required = false,
  placeholder = null,
  label = "Phone",
}) => {
  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters

    // If the phone number starts with '1', remove it
    if (input.startsWith("1")) {
      input = input.substring(1);
    }

    let x = input.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

    handleChange(e);
  };

  return (
    <TextField
      fullWidth
      required={required}
      name="phone"
      type="tel"
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      value={phone}
      onChange={handlePhoneChange}
      error={!!error}
      helperText={error}
    />
  );
};
export default PhoneField;
