import {
  ArrowBackIos,
  ArrowForwardIos,
  CalendarMonthRounded,
  Expand,
  Fullscreen,
} from "@mui/icons-material";
import {
  Collapse,
  Fade,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState, useRef } from "react";
import SwipeableViews from "react-swipeable-views";

const DatePicker = ({
  visits,
  filters,
  selectedDate,
  onChange,
  allowCollapse = true,
}) => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentWeek, setCurrentWeek] = useState(moment().startOf("week"));
  const [swipeIndex, setSwipeIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);




  const theme = useTheme();

  const currentVisits = new Set(
    visits.map((v) => moment(v.start).format("YYYY-MM-DD"))
  );

  useEffect(() => {
    console.log("=======> selectedDate: ", selectedDate);
    if (visits.length > 0 && selectedDate == null) {
      // Find the earliest visit
      const earliestVisit = visits.reduce((earliest, visit) =>
        moment(visit.start).isBefore(moment(earliest.start)) ? visit : earliest
      );

      // Calculate the month difference
      const earliestMonth = moment(earliestVisit.start).startOf("month");
      const monthDifference = earliestMonth.diff(
        currentMonth.clone().startOf("month"),
        "months"
      );

      console.log("in auto move swipe index");

      // Adjust the swipeIndex based on the month difference
      const newSwipeIndex = monthDifference + 1;

      setSwipeIndex(newSwipeIndex);
    }
  }, [visits]);

  // Makes sure that swipe has animation after using arrow keys
  useEffect(() => {
    if (swipeIndex === 1) {
      setShowAnimation(true);
      console.log("in use effect");
    }
    console.log("swipeIndex: ", swipeIndex);
  }, [swipeIndex, selectedDate]); // Dependency array with swipeIndex

  useEffect(() => {
    if (!selectedDate) handleExpand();
  }, [selectedDate]); // Dependency array with swipeIndex

  // useEffect(() => {
  //   if (selectedDate) handleDateSelection(selectedDate, 1);
  // }, []);

  const handleDateSelection = (dateString, weekIndex) => {
    setShowAnimation(false);
    onChange(dateString);
    if (allowCollapse) {
      setCurrentWeek(moment(dateString).startOf("week"));
      setSelectedWeekIndex(weekIndex); // Update the selected week index
    }
  };

  const generateCalendarDaysMonth = (month) => {
    const days = [];
    const startDay = month.clone().startOf("month").startOf("week");
    const endDay = month.clone().endOf("month").endOf("week");

    let date = startDay.clone().subtract(1, "day");
    while (date.isBefore(endDay, "day")) {
      days.push(
        Array(7)
          .fill(0)
          .map(() => date.add(1, "day").clone())
      );
    }

    return days;
  };

  const generateCalendarDaysWeek = (week) => {
    const days = [];
    // Calculate the start of the calendar view, which is a few weeks before the selected week
    const calendarStart = week
      .clone()
      .startOf("week")
      .subtract(selectedWeekIndex, "week");

    let weekStart = calendarStart.clone();

    // Generate six weeks of days
    for (let i = 0; i < 6; i++) {
      const weekDays = Array(7)
        .fill(0)
        .map((_, index) => weekStart.clone().add(index, "day"));
      days.push(weekDays);
      // Move to the next week
      weekStart = weekStart.add(1, "week");
    }

    return days;
  };

  const handleSwipe = (index) => {
    if (isTransitioning) return;

    console.log("handling swipe");
    if (isCollapsed) {
      setIsTransitioning(true);
      setSwipeIndex(0);
      setShowAnimation(true);
    }
    setSwipeIndex(index);
  };

  const handleBackwardClick = () => {
    if (isTransitioning) return;

    if (isCollapsed) {
      setIsTransitioning(true);
      setShowAnimation(true);
    }

    setSwipeIndex((prevSwipeIndex) =>
      prevSwipeIndex > 0 ? prevSwipeIndex - 1 : prevSwipeIndex
    );
  };

  const handleForwardClick = () => {
    if (isTransitioning) return;

    if (isCollapsed) {
      setIsTransitioning(true);
      setShowAnimation(true);
    }

    setSwipeIndex((prevSwipeIndex) =>
      prevSwipeIndex < 2 ? prevSwipeIndex + 1 : prevSwipeIndex
    );
  };

  // console.log("isTransitioning: ", isTransitioning);
  // console.log("swipeIndex: ", swipeIndex);
  // console.log("showAnimation: ", showAnimation);
  // console.log("isCollapsed: ", isCollapsed);

  const handleSwipeEnd = () => {
    console.log("swipeIndex: ", swipeIndex);
    console.log("isCollapsed: ", isCollapsed);

    if (!isTransitioning) return;

    console.log("handleSwipeEnd--------:", moment().format("hh:mm:ss a"));
    // Going to the next month
    if (swipeIndex === 2) {
      if (isCollapsed) {
        setShowAnimation(false);
        console.log("incrementing current week");
        setCurrentWeek((prevWeek) => prevWeek.clone().add(1, "week"));
        setSwipeIndex(1);
        setIsTransitioning(false);
      }
      // else {
      //   setShowAnimation(false);
      //   setCurrentMonth((prevMonth) => prevMonth.clone().add(1, "month"));
      //   setSwipeIndex(1);
      //   setIsTransitioning(false);
      // }
    }
    // Going to the previous month
    if (swipeIndex === 0) {
      if (isCollapsed) {
        console.log("decrementing current week");

        setShowAnimation(false);
        setCurrentWeek((prevWeek) => prevWeek.clone().subtract(1, "week"));
        setSwipeIndex(1);
        setIsTransitioning(false);
      }
      // else {
      //   setShowAnimation(false);
      //   setCurrentMonth((prevMonth) => prevMonth.clone().subtract(1, "month"));
      //   setSwipeIndex(1);
      //   setIsTransitioning(false);
      // }
    }
  };

  const renderDay = (day, weekIndex, monthIndex) => {
    const dateString = day.format("YYYY-MM-DD");
    let isCurrentMonth;

    const isVisitDay = currentVisits.has(dateString);

    const isSelected = selectedDate === dateString;

    if (!isCollapsed) {
      isCurrentMonth =
        day.month() ===
        currentMonth
          .clone()
          .add(monthIndex - 1, "month")
          .month();
      if (!isCurrentMonth) {
        return (
          <div style={{ flex: 1, minWidth: 0, padding: "2px" }} key={day}></div>
        ); // Render empty cell for days not in the current month
      }
    }

    const today = moment().format("YYYY-MM-DD") === dateString;

    let buttonStyle = {
      width: "40px", // Fixed width
      height: "40px", // Fixed height, equal to width to form a square
    };

    if (!isVisitDay) {
      buttonStyle = {
        ...buttonStyle,
        backgroundColor: "",
        color: "rgba(0, 0, 0, 0.38)",
      };
    }

    if (isVisitDay) {
      buttonStyle = {
        ...buttonStyle,
        // backgroundColor: theme.palette.primary.lighter,
        backgroundColor: "",
        color: "hsl(190,100%,30%)",
        border: "1px solid hsl(190,100%,30%)", // Add black outline if current day
      };
    }

    if (isSelected) {
      buttonStyle = {
        ...buttonStyle,
        backgroundColor: theme.palette.primary.main,
        color: "white", // White color for selected day
      };
    }

    // if (today) {
    //   buttonStyle = {
    //     ...buttonStyle,
    //     fontWeight: "600",
    //     // border: "1px solid rgba(0, 0, 0, 0.6)", // Add black outline if current day
    //     color: "rgba(0, 0, 0, 0.6)",
    //   };
    // }

    const key = `${dateString}-${isVisitDay}`;

    const dayContent = (
      // Replace this with your day rendering logic
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          minWidth: 0,
          padding: "2px",
        }}
        key={key}
      >
        <IconButton
          style={buttonStyle}
          onClick={() => handleDateSelection(dateString, weekIndex)}
          disabled={!isVisitDay}
        >
          <Typography
            // fontSize={today ? "1rem" : "0.75rem"}
            // fontWeight={today ? "bold" : ""}
            fontSize={"0.9rem"}
          >
            {day.format("D")}
          </Typography>
        </IconButton>
      </div>
    );

    return dayContent;

    // return isVisitDay ? (
    //   <Fade in={true} timeout={500}>
    //     {dayContent}
    //   </Fade>
    // ) : (
    //   dayContent
    // );
  };

  // Existing rendering for days in the current month

  const monthDifference = (date1, date2) => {
    return (date2.year() - date1.year()) * 12 + date2.month() - date1.month();
  };

  const handleExpand = () => {
    // setCurrentMonth(currentWeek.endOf("week").startOf("month"));
    setShowAnimation(false);
    setSelectedWeekIndex(null);
    setIsCollapsed(false);

    if (selectedDate) {
      const now = moment(); // Current date
      const date = moment(selectedDate); // Parse selectedDate from string
      const diff = monthDifference(now, date);

      setSwipeIndex(diff + 1);
    } else {
      setSwipeIndex(1);
    }

    // Set a delay of 500 milliseconds
    setTimeout(() => {
      setShowAnimation(true); // Assuming this is the line to turn on the animation
    }, 500);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
          // marginTop: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            transition: "transform 0.3s ease",
            transform:
              selectedWeekIndex !== null
                ? "translateX(0px)"
                : "translateX(-24px)", // Adjust the 10px to your preference
          }}
        >
          <IconButton
            onClick={handleExpand}
            style={{
              transition: "opacity 0.3s ease, transform 0.3s ease",
              opacity: selectedWeekIndex !== null ? 1 : 0,
            }}
          >
            <CalendarMonthRounded color={"primary"} />
          </IconButton>

          <Typography fontWeight={"500"} fontSize={"1rem"}>
            {isCollapsed
              ? currentWeek
                  .clone()
                  .add(swipeIndex - 1, "week")
                  .endOf("week")
                  .format("MMMM YYYY")
              : currentMonth
                  .clone()
                  .add(swipeIndex - 1, "month")
                  .format("MMMM YYYY")}
          </Typography>
        </div>
        <div>
          <IconButton
            onClick={handleBackwardClick}
            disabled={swipeIndex === 0 && !isCollapsed}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={handleForwardClick}
            disabled={swipeIndex === 2 && !isCollapsed}
          >
            <ArrowForwardIos />
          </IconButton>
        </div>
      </div>
      <SwipeableViews
        index={swipeIndex}
        onChangeIndex={handleSwipe}
        onTransitionEnd={handleSwipeEnd}
        enableMouseEvents
        animateTransitions={showAnimation}
        threshold={2} // Adjust this value as needed, smaller values require less swipe
        springConfig={{
          duration: "0.3s",
          easeFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          delay: "0s",
        }}
      >
        {[...Array(3)].map((_, viewIndex) => {
          const month = currentMonth.clone().add(viewIndex - 1, "month");
          const week = currentWeek.clone().add(viewIndex - 1, "week");

          const daysInView = isCollapsed
            ? generateCalendarDaysWeek(week)
            : generateCalendarDaysMonth(month);

          return (
            <div key={viewIndex}>
              <div style={{ display: "flex" }}>
                {["S", "M", "T", "W", "T", "F", "S"].map((d, idx) => (
                  <div
                    key={idx}
                    style={{
                      flex: 1,
                      minWidth: 0,
                      textAlign: "center",
                      margin: "2px",
                      marginBottom: "8px",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "0.75rem",
                    }}
                  >
                    {d}
                  </div>
                ))}
              </div>
              {daysInView.map((week, weekIndex) => (
                <Collapse
                  in={
                    !allowCollapse ||
                    selectedWeekIndex === null ||
                    selectedWeekIndex === weekIndex
                  }
                  key={weekIndex}
                  onExited={() => {
                    if (allowCollapse) {
                      setIsCollapsed(true);
                      setSwipeIndex(1);
                    }
                  }}
                >
                  <div key={weekIndex} style={{ display: "flex" }}>
                    {week.map((day) =>
                      renderDay(day, weekIndex, viewIndex - 1 + 1)
                    )}
                  </div>
                </Collapse>
              ))}
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );
};

export default DatePicker;
