import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

import { geohashForLocation, geohashQueryBounds } from "geofire-common";
import geohash from "ngeohash";

const BASE32 = "0123456789bcdefghjkmnpqrstuvwxyz";
const BITS = [16, 8, 4, 2, 1];

export function decodeGeoHash(geohash) {
  const bounds = decodeGeoHashToBounds(geohash);
  if (bounds.length === 0) return [0, 0];
  const lat = (bounds.sw.lat + bounds.ne.lat) / 2;
  const lng = (bounds.sw.lng + bounds.ne.lng) / 2;

  return [lat, lng];
}

export const getCityStateFromZip = async (zipCode) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;
  console.log("Request URL:", url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log("data: ", data);

    if (data.status === "OK") {
      const addressComponents = data.results[0].address_components;

      // Initialize variables to hold city and state
      let city = "";
      let state = "";

      // Extract city and state from address components
      for (let component of addressComponents) {
        if (component.types.includes("locality")) {
          city = component.long_name; // City name
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name; // State abbreviation
        }
      }

      if (city && state) {
        return `${city}, ${state}`;
      }

      return "City or state not found";
    } else {
      return zipCode;
    }
  } catch (error) {
    console.error("Error fetching city:", error);
    return zipCode;
  }
};

function decodeGeoHashToBounds(geohash) {
  if (!geohash) return [];

  let isEven = true;
  const lat = [-90.0, 90.0];
  const lon = [-180.0, 180.0];

  for (let i = 0; i < geohash.length; i++) {
    const c = geohash[i];
    const cd = BASE32.indexOf(c);
    for (let j = 0; j < 5; j++) {
      const mask = BITS[j];
      if (isEven) {
        refineInterval(lon, cd, mask);
      } else {
        refineInterval(lat, cd, mask);
      }
      isEven = !isEven;
    }
  }
  const latCenter = (lat[0] + lat[1]) / 2;
  const lonCenter = (lon[0] + lon[1]) / 2;

  return {
    sw: { lat: lat[0], lng: lon[0] },
    ne: { lat: lat[1], lng: lon[1] },
    center: { lat: latCenter, lng: lonCenter },
  };
}

function refineInterval(interval, cd, mask) {
  if (cd & mask) {
    interval[0] = (interval[0] + interval[1]) / 2;
  } else {
    interval[1] = (interval[0] + interval[1]) / 2;
  }
}

const stateAbbreviations = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const convertStateNameToAbbreviation = (input) => {
  // Check if input is a valid string, return it as is if not
  if (typeof input !== "string" || input.trim() === "") {
    return input;
  }

  const inputUpperCase = input.toUpperCase();

  const stateNames = Object.keys(stateAbbreviations);
  const stateAbbreviationValues = Object.values(stateAbbreviations);

  // Check if input is already an abbreviation
  if (stateAbbreviationValues.includes(inputUpperCase)) {
    return inputUpperCase;
  }

  // Convert full state name to abbreviation
  const foundState = stateNames.find(
    (stateName) => stateName.toUpperCase() === inputUpperCase
  );

  return foundState ? stateAbbreviations[foundState] : input;
};

// Function to get members in an area
export const getMembersInArea = async (
  { geohash, center },
  radiusInMiles = 5
) => {
  let searchCenter;

  console.log("center: ", center);

  if (geohash) {
    searchCenter = decodeGeoHash(geohash);
    console.log("using geohash");
  } else if (center && center.lat && center.lng) {
    searchCenter = [center.lat, center.lng];
    console.log("using center");
  } else {
    console.error("Either geohash or center with lat/lng is required");
    return [];
  }

  // console.log("searchCenter: ", searchCenter);

  const radiusInM = radiusInMiles * 1609.34; // Convert miles to meters
  const bounds = geohashQueryBounds(searchCenter, radiusInM);

  // console.log("bounds: ", bounds);

  let membersLocations = [];

  try {
    for (const [start, end] of bounds) {
      const q = query(
        collection(db, "membersPrivate"), // Adjust according to your collection name
        // where("status", "in", ["active", "waitlist"]), // Query for 'active' or 'waitlist'
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const querySnapshot = await getDocs(q);

      // console.log("querySnapshot: ", querySnapshot)

      querySnapshot.forEach((doc) => {
        const memberData = doc.data();
        let coords = decodeGeoHash(memberData?.location?.geohash);
        let memberCoordinates = {
          lat: memberData?.location?.lat,
          lng: memberData?.location?.lng,
          // lat: coords[0],
          // lng: coords[1],
          status: memberData?.status || "active",
        };
        // console.log("memberCoordinates: ", memberCoordinates)

        membersLocations.push(memberCoordinates);
      });
    }

    return membersLocations;
  } catch (error) {
    console.error("Error getting members: ", error);
    return [];
  }
};

// Utility function to fetch employees in the area based on lat/lng
export const getActiveEmployeesAtLocation = async (lat, lng, radiusInMiles = 10) => {
  const memberGeohash5 = geohashForLocation([lat, lng], 5); // Convert lat/lng to geohash precision 5
  const memberCoordinates = [lat, lng];
  const employeesData = {};

  // Query Firestore using array-contains to match the geohash
  const q = query(
    collection(db, "employees"),
    where("status", "==", "active"),
    where("geohash5Arr", "array-contains", memberGeohash5) // Check if the geohash exists in geohash5Arr
  );

  try {
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const employeeData = doc.data();

      if (employeeData?.location?.bounds) {
        if (isPointInPolygon(memberCoordinates, employeeData.location.bounds)) {
          employeesData[doc.id] = employeeData;
        }
      }
    });
  } catch (error) {
    console.error("Error fetching employees:", error);
  }

  return employeesData;
};

// NOT TESTED
export const fetchMembersInBounds = async (
  lat,
  lng,
  serviceBounds,
  radiusInMiles = 10
) => {
  // Convert radius to meters
  const radiusInMeters = radiusInMiles * 1609.34;
  const point = [lat, lng];

  const bounds = geohashQueryBounds(point, radiusInMeters);
  const allMembers = new Map();

  for (const [start, end] of bounds) {
    // Query Firebase Firestore
    const q = query(
      collection(db, "membersPrivate"),
      where("status", "==", "active"),
      orderBy("location.geohash"),
      startAt(start),
      endAt(end)
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const memberData = doc.data();

        const memberCoordinates = [
          memberData?.location?.lat,
          memberData?.location?.lng,
        ];

        if (isPointInPolygon(memberCoordinates, serviceBounds)) {
          allMembers.set(doc.id, memberData);
        }
      });
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  }

  // Convert map to object and return
  const aggregatedMembers = Object.fromEntries(allMembers);
  return aggregatedMembers;
};

export function isPointInPolygon(point, polygon) {
  let x, y;

  // Check if point is an array, assume [0] is lat and [1] is lng
  if (Array.isArray(point)) {
    x = point[0];
    y = point[1];
  } else {
    // If point is an object
    x = point.lat;
    y = point.lng;
  }

  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].lat,
      yi = polygon[i].lng;
    let xj = polygon[j].lat,
      yj = polygon[j].lng;

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

// Helper function to check if a bounding box intersects a polygon
function doesBoundingBoxIntersectPolygon(boundingBox, polygon) {
  const [minLat, maxLat, minLng, maxLng] = boundingBox;

  // Check if any polygon vertex is inside the bounding box
  const isVertexInBoundingBox = polygon.some(({ lat, lng }) => {
    return lat >= minLat && lat <= maxLat && lng >= minLng && lng <= maxLng;
  });

  if (isVertexInBoundingBox) return true;

  // Check if the bounding box vertices are inside the polygon
  const boundingBoxVertices = [
    [minLat, minLng],
    [minLat, maxLng],
    [maxLat, minLng],
    [maxLat, maxLng],
  ];

  const isBoundingBoxVertexInPolygon = boundingBoxVertices.some((vertex) =>
    isPointInPolygon(vertex, polygon)
  );

  if (isBoundingBoxVertexInPolygon) return true;

  return false;
}

// Generate geohashes intersecting a polygon
export const generateGeohashesInPolygon = (polygon, precision = 5) => {
  console.time("generateGeohashesInPolygon");

  const geohashes = new Set();

  console.log("polygon: ", polygon);

  // Step 1: Compute bounding box
  let minLat = Infinity,
    maxLat = -Infinity,
    minLng = Infinity,
    maxLng = -Infinity;

  // Use the correct structure to access lat/lng properties
  polygon.forEach(({ lat, lng }) => {
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  // Step 2: Get all geohashes within the bounding box
  const bboxHashes = geohash.bboxes(minLat, minLng, maxLat, maxLng, precision);
  console.log("bboxHashes: ", bboxHashes);

  // Step 3: Filter geohashes that intersect the polygon
  bboxHashes.forEach((hash) => {
    const [minLat, minLng, maxLat, maxLng] = geohash.decode_bbox(hash);
    const boundingBox = [minLat, maxLat, minLng, maxLng];

    // Use doesBoundingBoxIntersectPolygon to check intersection
    if (doesBoundingBoxIntersectPolygon(boundingBox, polygon)) {
      geohashes.add(hash);
    } else {
      console.log("Not in polygon: ", hash);
    }
  });

  console.timeEnd("generateGeohashesInPolygon");

  return Array.from(geohashes);
};
