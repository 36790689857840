import {
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { db } from "../firebase";
import { decodeGeoHash, isPointInPolygon } from "../services/locationServices";

const VisitContext = createContext();

const RescheduleProvider = ({ visit, employeesProp = {}, children }) => {
  const [employees, setEmployees] = useState(employeesProp);
  const [visitData, setVisitData] = useState({});
  const [loading, setLoading] = useState(true);

  // State to track availability and visits for each employee
  const [employeeAvailabilityData, setEmployeeAvailabilityData] = useState({});

  // Using a ref to store current unsubscribe functions to avoid triggering effect updates
  const unsubscribesRef = useRef([]);

  // !!!!! I think that these are unused actually, and that the filters are set in RescheduleVisitPublic
  // Define the initial filters inside useEffect to ensure they are updated with the latest visit data
  useEffect(() => {
    console.log("running Z");

    const initialFilters = {
      duration: 2,
      frequency:
        visit?.seriesEmployeesNeeded > 0 ? visit.recurrence.frequency : 0,
      employeeIds: [],
      numEmployees: "1",
    };

    console.log("setting frequency to: ", initialFilters.frequency);
    console.log("visit: ", visit);

    setVisitData({
      visit,
      filters: initialFilters,
    });

    // You may also control loading state here if required
    setLoading(false);
  }, [visit]);

  // Set lookup for local employees within visit?.location?.geohash
  useEffect(() => {
    // Skip this effect if 'employees' prop is provided and not empty
    if (Object.keys(employees).length > 0) {
      console.log("employees provided, skipping load employees by geohash");
      setVisitData((prevVisitData) => ({
        ...prevVisitData,
        employees: employees, // Set with provided employees
      }));
      return;
    }

    // Reset unsubscribe functions from possible previous use
    unsubscribesRef.current.forEach((unsubscribe) => unsubscribe());
    unsubscribesRef.current = [];

    if (!visit?.location?.geohash) return;

    const memberGeohash5 = visit?.location?.geohash.slice(0, 5); // Use the member's precise geohash
    const memberCoordinates = decodeGeoHash(visit?.location?.geohash); // { lat, lng } pair

    console.log("visit memberGeohash5: ", memberGeohash5);

    // Query Firestore using array-contains to match the geohash
    const q = query(
      collection(db, "employees"),
      where("status", "==", "active"),
      where("geohash5Arr", "array-contains", memberGeohash5) // Check if the geohash exists in geohash5Arr
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const employeesData = {};

      querySnapshot.forEach((doc) => {
        const employeeData = doc.data();
        if (employeeData?.location?.bounds !== undefined) {
          if (
            isPointInPolygon(memberCoordinates, employeeData.location.bounds)
          ) {
            console.log("employeeData: ", employeeData);
            employeesData[doc.id] = employeeData;
          }
        }
      });
      // Update state with all aggregated employees
      setVisitData((prevVisitData) => ({
        ...prevVisitData,
        employees: employeesData,
      }));
    });

    // Cleanup function
    return () => {
      // Unsubscribe from all listeners
      unsubscribe();

      // Clear the employees data from state or reset to initial state
      setVisitData((prevVisitData) => ({
        ...prevVisitData,
        employees: {}, // Resetting employees data
      }));
    };
  }, [visit?.location?.geohash, employees]); // Depend on selectedMember so it re-runs the effect when it changes

  // Function to subtract windows from visits
  function subtractWindowsFromVisits(windows, visits) {
    const adjustedWindows = [];
    let visitIndex = 0;
    let windowIndex = 0;

    // Convert objects to arrays and sort them
    const windowsArray = Object.values(windows).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );
    const visitsArray = Object.values(visits).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );

    while (windowIndex < windowsArray.length) {
      const window = windowsArray[windowIndex];

      if (visitIndex < visitsArray.length) {
        const visit = visitsArray[visitIndex];

        if (visit.end.toMillis() <= window.start.toMillis()) {
          // Visit ends before window starts, move to next visit
          visitIndex++;
        } else if (visit.start.toMillis() >= window.end.toMillis()) {
          // Visit starts after window ends, add whole window to adjustedWindows
          adjustedWindows.push(window);
          windowIndex++;
        } else {
          // Visit is within the window. Split the window.
          if (window.start.toMillis() < visit.start.toMillis()) {
            adjustedWindows.push({ start: window.start, end: visit.start });
          }
          // Set the start of the window to the end of the visit for the next loop iteration
          // If the adjusted window has zero length or is negative, skip to the next window
          if (window.end.toMillis() > visit.end.toMillis()) {
            window.start = visit.end;
            visitIndex++;
          } else {
            windowIndex++;
          }
        }
      } else {
        // No more visitsArray to check against, just add the remaining windowsArray
        adjustedWindows.push(window);
        windowIndex++;
      }
    }

    return adjustedWindows;
  }

  // Function to pad visits to give travel time
  function padVisits(visits, paddingMinutes) {
    return Object.entries(visits).reduce((acc, [id, visit]) => {
      if (!visit.start || !visit.end) {
        return acc;
      }

      acc[id] = {
        start: new Date(
          visit.start.toDate().getTime() - paddingMinutes * 60 * 1000
        ),
        end: new Date(
          visit.end.toDate().getTime() + paddingMinutes * 60 * 1000
        ),
      };

      // Convert back to Firebase Timestamp
      acc[id].start = Timestamp.fromMillis(acc[id].start);
      acc[id].end = Timestamp.fromMillis(acc[id].end);

      return acc;
    }, {});
  }

  console.log("------ visitData: ", visitData);

  // Fetch availability and visits independently
  useEffect(() => {
    console.log("running B");

    if (!visitData.employees || Object.keys(visitData.employees).length === 0)
      return;

    const unsubscribes = [];

    Object.entries(visitData.employees)
      .slice(0, 20)
      .forEach(([employeeId, employeeData]) => {
        // Fetch visits
        const visitsQuery = query(
          collection(db, "visits"),
          where("employeeArr", "array-contains", employeeId),
          where("status", "==", "confirmed")
        );
        unsubscribes.push(
          onSnapshot(visitsQuery, (snapshot) => {
            const visits = snapshot.docs.reduce((acc, doc) => {
              acc[doc.id] = doc.data();
              return acc;
            }, {});
            setEmployeeAvailabilityData((prevData) => ({
              ...prevData,
              [employeeId]: {
                ...prevData[employeeId],
                visits,
                availability: employeeData.availability,
              },
            }));
          })
        );
      });

    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [visitData.employees]);

  // Calculate employeeAvailability when either availability or visits data changes
  useEffect(() => {
    console.log("running C");

    setVisitData((prev) => {
      const updatedEmployeeAvailability = { ...prev.employeeAvailability };
      Object.entries(employeeAvailabilityData).forEach(([employeeId, data]) => {
        if (data.availability && data.visits) {
          // Access additional properties from the employees prop
          const {
            bookingDaysInAdvance,
            isAvailableToday,
            avatarUrl,
            firstName,
            lastName,
          } = visitData.employees[employeeId];

          const paddedVisits = padVisits(data.visits, 30);
          const availableWindows = subtractWindowsFromVisits(
            data.availability.windows || [],
            paddedVisits
          );

          updatedEmployeeAvailability[employeeId] = {
            ...data,
            availableWindows,
            bookingDaysInAdvance,
            isAvailableToday,
            avatarUrl,
            firstName,
            lastName,
          };
        }
      });

      return {
        ...prev,
        employeeAvailability: updatedEmployeeAvailability,
      };
    });
  }, [employeeAvailabilityData]);

  return (
    <VisitContext.Provider value={{ visitData, setVisitData, loading }}>
      {children}
    </VisitContext.Provider>
  );
};

export default RescheduleProvider;

export const useReschedule = () => {
  return useContext(VisitContext);
};
