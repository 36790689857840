/* global google */

import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  CardHeader,
  MenuItem,
  Typography,
  useTheme,
  Grid,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // LinearProgress,
} from "@mui/material";
// import LinearProgress from '@mui/material/LinearProgress';

import { ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/system";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useEmployee } from "../providers/EmployeeProvider";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

import { createEmployee } from "../services/employeeServices";
import AvatarUpload from "../components/AvatarUpload";
import EmployeeCreateAccountForm from "../forms/EmployeeCreateAccountForm";
import FooterSimple from "../components/FooterSimple";
import SimplePage from "../components/SimplePage";
import StyledProgress from "../components/StyledProgress";

const CreateEmployeePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const authSession = getAuth();

  const [errors, setErrors] = useState({});
  const addressRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [adSources, setAdSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState(
    "Creating your profile..."
  );

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      updateProgress(90, "Creating your profile..."); // Progress is at 33% after first step
      await createEmployee(values);

      updateProgress(100, "All set! Redirecting..."); // Progress is at 100% after final step

      redirectToDashboard();
    } catch (error) {
      console.error("Error creating employee:", error);
      clearInterval(window.progressInterval); // Clear the interval on error
      setProgress(0);
      setLoading(false);
      setProgressMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop the spinner after form submission completes
    }
  };

  const updateProgress = (targetProgress, message) => {
    // Update the progress message
    setProgressMessage(message);

    // Clear any existing interval
    clearInterval(window.progressInterval);

    // Set up a new interval
    window.progressInterval = setInterval(() => {
      setProgress((oldProgress) => {
        // Determine the gap between the current progress and the target
        const gap = targetProgress - oldProgress;

        // Adjust the speed: the closer we are to the target, the smaller the increment
        // This creates a "slowing down" effect as we approach the target
        const speedFactor = gap / 4; // Adjust this factor to control the "slowing down" effect

        // Random increment value, influenced by the speedFactor
        // The increment is smaller when the gap is smaller
        const randomIncrement = (Math.random() * 2 + 1) * speedFactor;

        // Calculate the new progress
        let newProgress = oldProgress + randomIncrement;

        // If the new progress reached the target, clear the interval
        if (newProgress >= targetProgress) {
          clearInterval(window.progressInterval);
          newProgress = targetProgress; // Ensure we don't exceed the target progress
        }

        // Ensure the new progress doesn't exceed 100%
        return Math.min(newProgress, 100);
      });
    }, 200); // Adjust the interval timing based on preference
  };

  const redirectToDashboard = async () => {
    try {
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to fetch employee data:", error);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh" // This ensures it takes up the full viewport height
          bgcolor="background.paper" // Just for ensuring the background isn't the same color as the progress bar
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="50vw" // Adjust if the viewport is too narrow, affecting the width of the progress bar
            minWidth="300px" // Minimum width for narrower screens
          >
            <Typography variant="h6" gutterBottom>
              {progressMessage}
            </Typography>
            <StyledProgress variant="determinate" value={progress} />
          </Box>
        </Box>
      ) : (
        <SimplePage
          top={"20vh"}
          maxWidth={"400px"}
          align="top"
          showFooterMobile={false}
          buttonOffset={true}
        >
          <EmployeeCreateAccountForm handleSubmit={handleSubmit} />
        </SimplePage>
      )}
    </>
  );
};

export default CreateEmployeePage;
