import { Box, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import useTrackVisibility from "../../../hooks/useTrackVisibility";
import ServicesGrid from "./ServicesGrid";

function EmployeeJobDescription({ pageName = "" }) {
  const refJobDescription = useTrackVisibility(() => {},
  `${pageName}/"job description"`); // Pass the logMessage here

  const refAboutUs = useTrackVisibility(() => {}, `${pageName}/"about us"`); // Pass the logMessage here

  const refResponsibilities = useTrackVisibility(() => {},
  `${pageName}/"what you'll do"`); // Pass the logMessage here

  const refPerks = useTrackVisibility(() => {},
  `${pageName}/"perks and opportunities"`); // Pass the logMessage here

  const refSkills = useTrackVisibility(() => {},
  `${pageName}/"skills and requirements"`); // Pass the logMessage here

  const refFAQ = useTrackVisibility(() => {}, `${pageName}/"hiring FAQ"`); // Pass the logMessage here

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.lightest,
      }}
    >
      <Container
        sx={{
          paddingY: { xs: 2, sm: 8 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            maxWidth: "600px",
          }}
        >
          <Box ref={refJobDescription}>
            <Typography
              variant="h2"
              sx={{ fontSize: { xs: "42px", sm: "60px" } }}
              gutterBottom
            >
              Job Description
            </Typography>

            {/* <Typography variant="h5">Overview</Typography> */}

            <ul style={{ marginTop: 0, paddingLeft: "24px" }}>
              {[
                { label: "Age:", text: "18+" },
                { label: "Flexible:", text: "Set your own schedule" },
                {
                  label: "Part-time:",
                  text: "Works great with existing jobs or classes",
                },
                { label: "Pay:", text: "Competitive wages and opportunities" },
                { label: "Local:", text: "Draw your service map boundary" },
                { label: "Vacation:", text: "Unlimited time-off" }, // No label for this one
                // { label: "Sales opportunites:", text: "up to $80/hr average" },
              ].map((item, index) => (
                <li key={index}>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>{item.label}</span>{" "}
                    {item.text}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
          <Box ref={refAboutUs}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              About Us
            </Typography>

            <Typography variant="body1" paragraph>
              Linked Lives helps older adults (age 55+) with simple and low-risk
              household tasks, as needed. We prioritize exceptional service and
              meaningful connections.
            </Typography>

            <Typography
              variant="body1"
              paddingX={4}
              paragraph
              sx={{ fontStyle: "italic" }}
            >
              “Our mission is to foster meaningful, intergenerational
              relationships through excellent, local, and dependable service.”
            </Typography>
          </Box>

          {/* <Typography variant="h5">Job Summary</Typography>

          <Typography variant="body1" paragraph>
            As a Link Employee, you will help older adults members within
            with physical tasks around the house. During a typical 2-hour home
            visit, you will help with a wide range of basic household tasks,
            from organizing to tech help, moving boxes to light garden work, and
            more.
          </Typography> */}
          <Box ref={refResponsibilities}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              What You’ll Do
            </Typography>

            {/* <Typography variant="body1">
              As a Link Employee, you are in charge of the following:
            </Typography> */}

            <ul style={{ paddingLeft: "24px" }}>
              {[
                {
                  label: "Set your availability",
                  text: "Keep your availability up-to-date",
                },
                { label: "Check your schedule", text: "Check your weekly schedule" },
                { label: "Drive to member's homes", text: "Drive to member’s homes" },
                {
                  label: "Assist with simple tasks",
                  text: "Assist older adults with household tasks such as...",
                },
                // { label: "Meetings:", text: "Attend monthly meetings" },
                // { label: "Recruitment:", text: "Recruit other Link Employees" },
                // { label: "Growth:", text: "Grow the brand" },
                // { label: "Fundraising:", text: "Fundraise for your education" },
              ].map((item, index) => (
                <li key={index}>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>{item.label}</span>{" "}
                    {/* {item.text} */}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>

          <ServicesGrid expanded={false} pageName={pageName} listOnly={true} />
          <div style={{ height: "32px" }} />

          <Box ref={refPerks}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Perks & Opportunities
            </Typography>

            <Typography variant="body1">
              You will enjoy a variety of perks that help you grow both
              personally and professionally. We value excellence and support you
              along your journey!
            </Typography>

            <ul>
              {[
                "Performance-based raise opportunities",
                "Great part-time work during college",
                "Excellent resume building",
                "Letters of recommendation",
                // "Leadership opportunities",
                // "Small business mentorship",
                "Unlimited time-off",
                "Set your own schedule",
                // "Profitable fundraising opportunities",
              ].map((item, index) => (
                <li key={index}>
                  <Typography variant="body1">{item}</Typography>
                </li>
              ))}
            </ul>
          </Box>

          <Box ref={refSkills}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Skills / Requirements:
            </Typography>

            <Typography variant="body1">
              You will be a great fit if you have experience with household
              help, personal interaction, time management, and problem-solving
              skills.
            </Typography>

            <ul>
              {[
                "18+ years old",
                "Yard work (weed a flower bed, dig up a shrub, trim bushes)",
                "Basic tech help (reset passwords, organizing photos, installing app’s)",
                "Handy around the house (hang paintings, change lightbulbs, assemble items)",
                "Cleaning tasks (dusting, vacuuming, mopping floors, organizing spaces)",
                "Physically capable of lifting heavy boxes and using ladders",
                "Good personal skills and communication",
                "Dependable, reliable, and excellent",
                "Time management skills",
                "Attention to detail",
              ].map((item, index) => (
                <li key={index}>
                  <Typography variant="body1">{item}</Typography>
                </li>
              ))}
            </ul>
          </Box>

          <Box ref={refFAQ}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              FAQ
            </Typography>

            <Typography variant="body1">
              Here's a list of common questions that our candidates ask while
              going through our hiring process. If you still have questions,
              feel free to reach out!
            </Typography>

            <Box
              sx={{
                paddingLeft: "24px",
                mt: "16px",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {[
                {
                  question: "Q: How do you set your schedule?",
                  answer:
                    "A: Our app allows you to add or remove availability, anytime.",
                },
                {
                  question: "Q: What if I can only work one day a week?",
                  answer:
                    "A: That's fine! You can add as much or as little availability as you want.",
                },
                {
                  question: "Q: Will I have to do medical or nursing tasks?",
                  answer:
                    "A: No. We do not perform any home health care. Our members are fully independent and vibrant.",
                },
              ].map((item, index) => (
                <Box key={index}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.question}
                  </Typography>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    {item.answer}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default EmployeeJobDescription;
