import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import GuestHeader from "../../components/GuestHeader";
import ServiceLookup from "../../components/locations/ServiceLookup";
import { logPageVisit } from "../../hooks/useLogPageVisit";
import WebFooter from "./sections/WebFooter";

function LocationsPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  logPageVisit("/locations");

  return (
    <div>
      <Helmet>
        <title>Senior Household Help Services Near You</title>
        <meta
          name="description"
          content="Our senior services provide a wide range of support, catering to the tasks that you either don’t want to or can’t do anymore."
        />
      </Helmet>
      <GuestHeader />
      <Box sx={{ minHeight: "70vh", display: "flex", alignItems: "center" }}>
        <ServiceLookup pageName="/locations" />
      </Box>
      {/* <FAQSection pageName="/locations" /> */}
      <WebFooter pageName="/locations" />
    </div>
  );
}

export default LocationsPage;
