// VisitPostCard.js
import {
  Box,
  Card,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Rating,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import AvatarStatusBadge from "../AvatarStatusBadge";
import ColorAvatar from "../ColorAvatar";

// Helper function to remove sensitive information
const removeSensitiveInfo = (text) => {
  if (!text) return "";

  const phoneRegex = /(\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/g;
  text = text.replace(phoneRegex, "");

  const addressRegex =
    /\b\d+\s+\w+\s+(Street|St|Avenue|Ave|Boulevard|Blvd|Road|Rd|Lane|Ln|Drive|Dr)\b/i;
  text = text.replace(addressRegex, "");

  return text;
};

function formatTimeDifference(startTimestamp, endTimestamp) {
  const startTime = moment(startTimestamp.toDate());
  const endTime = moment(endTimestamp.toDate());

  const duration = moment.duration(endTime.diff(startTime));

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  let result = "";

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? "s" : ""}`;
    if (minutes > 0) {
      result += ` ${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
  } else {
    result = `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  return result;
}

const VisitPostCard = ({ visit, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const descriptionRef = useRef(null);

  // Check if the description overflows
  useEffect(() => {
    if (descriptionRef.current) {
      setShowMore(
        descriptionRef.current.scrollHeight >
          descriptionRef.current.clientHeight
      );
    }
  }, [visit.description]);

  // Check if card should be displayed
  if (visit.rating < 5 || !visit.description) {
    return null;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        boxShadow: "0px 8px 8px 0px rgba(0,0,0,0.1)",
        width: "100%",
        height: "100%",
        mb: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "visible",
      }}
    >
      <Box
        sx={{
          my: 2,
          mx: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
          <ColorAvatar avatarUrl={visit.avatarUrl} name={visit.displayName} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="subtitle1" fontWeight="600">
              {visit.displayName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {moment(new Date(visit.start.seconds * 1000)).format(
                "MMM D, YYYY"
              )}{" "}
              • {formatTimeDifference(visit.start, visit.end)}
            </Typography>
          </Box>
        </Box>

        {/* Collapsible Description */}
        <Box
          ref={descriptionRef}
          sx={{
            my: 1,
            maxHeight: expanded ? "none" : 100, // Limit to two lines when collapsed
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: expanded ? "unset" : 4,
            position: "relative",
          }}
        >
          <Typography sx={{ boxSizing: "border-box" }}>
            {removeSensitiveInfo(visit.description)}
          </Typography>
          {showMore && (
            <Typography
              onClick={handleExpandClick}
              sx={{
                textTransform: "none",
                position: "absolute",
                bottom: 0,
                right: 0,
                fontSize: "0.9rem",
                color: "primary.main",
                backgroundColor: "white",
                pl: 1,
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {expanded ? "...less" : "...more"}
            </Typography>
          )}
        </Box>

        {/* Show (more) button conditionally */}

        {visit.rating && (
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              p: 1,
              borderRadius: "15px",
              my: 1,
            }}
          >
            <Rating
              size="small"
              value={visit.rating}
              precision={0.1}
              readOnly
            />
            <Typography variant="body1">
              {removeSensitiveInfo(visit.review)}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            ml: "auto",
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            justifyContent: "flex-end",
          }}
        >
          {Object.entries(visit.employees)
            .filter(([, employee]) => employee.responseStatus === "accepted")
            .map(([key, employee], index) => (
              <Box display="flex" justifyContent="flex-end" key={key}>
                <ListItemButton
                  onClick={() => onClick(key)}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                    borderRadius: "15px",
                    p: 1,
                  }}
                >
                  <ListItemAvatar>
                    <AvatarStatusBadge
                      status={employee.responseStatus}
                      avatarUrl={employee.avatarUrl}
                      displayName={employee.firstName}
                      index={index}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={employee.displayName}
                    secondary="completed"
                  />
                </ListItemButton>
              </Box>
            ))}
        </Box>
      </Box>
    </Card>
  );
};

export default VisitPostCard;
