// --/services/employeeServices.js
import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase";

import { getFunctions, httpsCallable } from "firebase/functions";
import { DateTime } from "luxon"; // Ensure luxon is installed and imported correctly
import { applyBoost, incrementStreak, resetStreak } from "./progressServices";

export const onDeclineVisit = async ({
  cancellationType = "this_visit", // Renamed from 'values' for clarity
  contactOutcome = null,
  declineMessage = "",
  reason,
  currentEmployeeId,
  visit,
  visitId,
  visits, // map of all loaded visit objects for the employee
  handleDecline = () => {}, // Pass handleAccept as a callback
  setLoading, // Pass setLoading as a callback
  availabilityWindows,
  showSnackbar,
}) => {
  setLoading("decline");

  try {
    let updatedWindows = availabilityWindows;

    const promises = [];

    // close parent dialog
    handleDecline();

    const futureVisits =
      visit?.recurrence?.recurringEventId && cancellationType === "all_visits"
        ? Object.entries(visits || {})
            .filter(
              ([id, v]) =>
                v.recurrence?.recurringEventId ===
                  visit?.recurrence.recurringEventId &&
                v.start.toDate() >= visit?.start.toDate()
            )
            .map(([id, v]) => ({ ...v, id }))
            .sort((a, b) => a.start.toDate() - b.start.toDate())
        : [{ ...visit, id: visitId }]; // Fallback to only the current visit if non-recurring

    for (const visitItem of futureVisits) {
      updatedWindows = subtractAndMergeWindows({
        availability: updatedWindows,
        removeWindow: {
          start: moment(visitItem.start.toDate())
            .subtract(30, "minutes")
            .toDate(), // Subtract 30 minutes from start
          end: moment(visitItem.end.toDate()).add(30, "minutes").toDate(), // Add 30 minutes to end
        },
      });

      const visitValues = {
        employeeId: currentEmployeeId,
        visitId: visitItem.id, // Assuming each visit has a unique ID
        visit: visitItem,
        declineReason: reason,
        cancellationType: cancellationType,
        declineMessage: declineMessage,
        contactOutcome,
      };

      // Update responseStatus and declineReason in Firestore for each future visit
      // const visitItemDocRef = doc(db, "visits", visitItem.id);
      // updateDoc(visitItemDocRef, {
      //   [`employees.${currentEmployeeId}.responseStatus`]: "declined",
      //   [`employees.${currentEmployeeId}.declineReason`]: reason,
      // });

      console.log("DeclineVisit visit values:, ", visitValues);

      // fire and forget the GCF
      // Add the pickupVisit promise to the array without waiting for it to complete
      // promises.push(declineVisit(visitValues));

      try {
        // STREAK - Check if the response is "needsAction" (i.e., first response)
        if (
          visit?.employee?.[currentEmployeeId]?.responseStatus === "needsAction"
        ) {
          // Calculate the time difference between visit creation and now
          const hoursSinceCreation = DateTime.now().diff(
            DateTime.fromJSDate(visit?.created.toDate()),
            "hours"
          ).hours;

          const hoursSinceUpdate = visit?.updated
            ? DateTime.now().diff(
                DateTime.fromJSDate(visit.updated.toDate()),
                "hours"
              ).hours
            : Infinity; // If `updated` does not exist, set hoursSinceUpdate to a large number

          // Get the smaller of the two
          const hoursSinceCreationOrUpdate = Math.min(
            hoursSinceCreation,
            hoursSinceUpdate
          );

          // Increment streak if within 48 hours, otherwise reset streak
          if (hoursSinceCreationOrUpdate < 48) {
            await incrementStreak(currentEmployeeId, "respondingEarly");
          } else {
            await resetStreak(currentEmployeeId, "respondingEarly");
          }
        }

        await declineVisit(visitValues);
        console.log("The visit was declined successfully.");
        // Optionally, add additional actions like showing success feedback to the user
      } catch (error) {
        console.error("Error in declineVisit:", error);
        // Optionally, log this error to a monitoring service or show an error message to the user
      }

      // Break the loop if it's only for the current visit
      if (cancellationType !== "all_visits" && visitItem.id === visitId) {
        break;
      }
    }
    // Update the employee's availability after processing all visits
    const employeeDocRef = doc(db, "employees", currentEmployeeId);

    await updateDoc(employeeDocRef, {
      "availability.windows": updatedWindows,
    });

    // Reset the visits accepted streak
    await resetStreak(currentEmployeeId, "visitsAccepted");

    // Wait for firebase operation to return
    // await Promise.all(promises);
    console.log("all promises complete");
    // Optionally wait for handleClaimOpen promises if needed
  } catch (error) {
    // Handle errors here
    showSnackbar("Error declining visit. Try again", "error");
    console.error("Error processing decline:", error);
  } finally {
    setLoading(false); // Reset the flag regardless of the outcome
  }
};

export const declineVisit = async ({
  employeeId,
  visitId,
  visit,
  cancellationType,
  declineReason = "other",
  declineMessage = "",
  contactOutcome = null,
}) => {
  console.log("Starting decline Visit");

  // Ensure required parameters are provided
  if (!employeeId || !visitId || !visit) {
    throw new Error(
      "Missing required arguments: employeeId, visitId, or visit."
    );
  }

  try {
    // Reference to the visit document in Firestore
    const visitRef = doc(db, "visits", visitId);

    // Fetch the visit document
    const visitDoc = await getDoc(visitRef);
    if (!visitDoc.exists()) {
      throw new Error("Visit document does not exist.");
    }

    const visitData = visitDoc.data();

    // Check if the employee has already declined the visit
    // if (visitData.hideEmployeeArr?.includes(employeeId)) {
    //   console.log(
    //     "Employee already in hideEmployeeArr, aborting decline operation."
    //   );
    //   return { result: "Employee already declined this visit" };
    // }

    // Calculate expiration if necessary
    const expiration = calculateExpiration(
      visit,
      visit.timeZoneId,
      visit.employeeArr.length
    );

    // Data to update the Firestore document
    const updateData = {
      [`employees.${employeeId}.responseStatus`]: "declined",
      [`employees.${employeeId}.declineReason`]: declineReason,
      [`employees.${employeeId}.declineMessage`]: declineMessage,
      [`employees.${employeeId}.contactOutcome`]: contactOutcome,
      employeeArr: arrayRemove(employeeId),
      hideEmployeeArr: arrayUnion(employeeId),
      employeesNeeded: increment(1),
      isOpenVisit: true,
    };

    // If it's an "all visits" cancellation, increment the seriesEmployeesNeeded
    if (cancellationType === "all_visits") {
      updateData["seriesEmployeesNeeded"] = increment(1);
    }

    // Add expiration to the updateData if it exists
    if (expiration) {
      updateData.expiration = Timestamp.fromDate(expiration.toDate());
    }

    // Update the visit document with the new response status and other data
    await updateDoc(visitRef, updateData);

    console.info("Updated visit response successfully");

    return { result: "The visit was declined successfully" };
  } catch (error) {
    console.error("An error occurred while declining the visit:", error);
    throw new Error("An error occurred while declining the visit.");
  }
};

function calculateExpiration(visit, timeZoneId, employeeArrLength) {
  const now = moment().tz(timeZoneId);
  let expiration;
  let maxExpiration;
  let minExpiration = moment().tz(timeZoneId); // Set a default value for minExpiration
  const startTime = moment(new Date(visit.start.seconds * 1000)).tz(timeZoneId);

  if (employeeArrLength === 1) {
    if (visit.reminderSent) {
      expiration = now.add(6, "hours");
      maxExpiration = moment(startTime).subtract(1, "hour");
    } else {
      const diffHours = startTime.diff(now, "hours");
      console.log("diffHours: ", diffHours);

      expiration = now.add(diffHours / 2, "hours");
      console.log("expiration: ", expiration);

      maxExpiration = moment(startTime).subtract(2, "days").hour(10).minute(0);
      console.log("maxExpiration: ", maxExpiration);

      minExpiration = moment(startTime).subtract(7, "days");
      console.log("minExpiration: ", minExpiration);
    }

    // Ensure expiration is within min and max bounds
    if (expiration.isBefore(minExpiration)) {
      expiration = minExpiration;
    } else if (expiration.isAfter(maxExpiration)) {
      expiration = maxExpiration;
    }

    if (expiration.hour() < 8) {
      expiration.hour(8).minute(0);
    } else if (
      expiration.hour() > 18 ||
      (expiration.hour() === 18 && expiration.minute() > 0)
    ) {
      expiration.add(1, "days").hour(8).minute(0);
    }

    // Round to nearest hour
    expiration =
      expiration.minute() >= 30
        ? expiration.add(1, "hour").startOf("hour")
        : expiration.startOf("hour");
  }

  return expiration ? expiration : null;
}

export const onPickupVisit = async ({
  confirmationType = "this_visit", // Renamed from 'values' for clarity
  currentEmployeeId,
  visit,
  visitId,
  employeeData,
  openVisits, // map of all loaded visit objects for the employee
  handlePickup = () => {}, // Pass handleAccept as a callback
  setLoading, // Pass setLoading as a callback
  availabilityWindows,
  showSnackbar,
}) => {
  setLoading("pickup");

  try {
    console.log("onPickupVisit parameters:", {
      confirmationType,
      currentEmployeeId,
      visit,
      visitId,
      employeeData,
      openVisits,
      handlePickup: !!handlePickup, // Checking if the function is provided
      setLoading: !!setLoading, // Checking if the function is provided
      availabilityWindows,
      showSnackbar: !!showSnackbar, // Checking if the function is provided
    });

    let updatedWindows = availabilityWindows; //data?.employee?.availability?.windows;
    // const promises = [];

    const futureOpenVisits =
      visit?.recurrence?.recurringEventId && confirmationType === "all_visits"
        ? Object.entries(openVisits || {})
            .filter(
              ([id, v]) =>
                v.recurrence?.recurringEventId ===
                  visit?.recurrence.recurringEventId &&
                v.start.toDate() >= visit?.start.toDate()
            )
            .map(([id, v]) => ({ ...v, id }))
            .sort((a, b) => a.start.toDate() - b.start.toDate()) // Sorting the visits
        : [{ ...visit, id: visitId }]; // Fallback to only the current visit if non-recurring

    handlePickup();

    for (const visitItem of futureOpenVisits) {
      updatedWindows = addAndMergeWindows({
        availability: updatedWindows,
        addWindow: {
          start: visitItem.start.toDate(),
          end: visitItem.end.toDate(),
        },
      });

      try {
        await pickupVisit({
          responseStatus: "accepted", //visitItem.id === visitId ? "accepted" : "needsAction",
          employeeId: currentEmployeeId,
          employeeData,
          availability: updatedWindows,
          visitId: visitItem.id,
          visitData: openVisits[visitItem.id],
          confirmationType,
        });
      } catch (error) {
        console.error("Error in declineVisit:", error);
        // Optionally, log this error to a monitoring service or show an error message to the user
      }

      // Break the loop if it's only for the current visit
      if (confirmationType !== "all_visits" && visitItem.id === visitId) {
        break;
      }
    }

    // Update the employee's availability after processing the visits
    const employeeDocRef = doc(
      db,
      "employees",
      currentEmployeeId
    );

    await updateDoc(employeeDocRef, {
      "availability.windows": updatedWindows,
    });

    await applyBoost(currentEmployeeId, "openVisit");
  } catch (error) {
    // Handle errors here
    showSnackbar("Error claiming visit. Try again", "error");
    console.error("Error processing claims:", error);
  } finally {
    setLoading(false); // Reset the flag regardless of the outcome
  }
};

export const onHideOpenVisit = async ({
  currentEmployeeId,
  visitId,
  setLoading,
  showSnackbar,
  handleHide,
}) => {
  setLoading("hide");

  try {
    // Reference to the visit document in Firestore
    const visitDocRef = doc(db, "visits", visitId);

    // Use arrayUnion to add the employeeId to hideEmployeeArr only if it's not already present
    await updateDoc(visitDocRef, {
      hideEmployeeArr: arrayUnion(currentEmployeeId),
    });

    handleHide();

    console.log(`Employee ${currentEmployeeId} hidden for visit ${visitId}.`);
  } catch (error) {
    console.error("Error hiding visit:", error);
    showSnackbar("Error hiding visit. Try again", "error");
  } finally {
    setLoading(false); // Reset the loading state regardless of the outcome
  }
};

export const onAcceptVisit = async ({
  confirmationType = "this_visit", // Renamed from 'values' for clarity
  currentEmployeeId,
  visit,
  visitId,
  visits, // map of all loaded visit objects for the employee
  handleAccept = () => {}, // Pass handleAccept as a callback
  setLoading, // Pass setLoading as a callback
  showSnackbar,
}) => {
  setLoading("accept");

  console.log("onAcceptVisit parameters:", {
    confirmationType,
    currentEmployeeId,
    visit,
    visitId,
    visits,
    handleAccept: !!handleAccept, // Checking if the function is provided
    setLoading: !!setLoading, // Checking if the function is provided
    showSnackbar: !!showSnackbar, // Checking if the function is provided
  });

  try {
    const futureVisits =
      visit?.recurrence?.recurringEventId && confirmationType === "all_visits"
        ? Object.entries(visits || {})
            .filter(
              ([id, v]) =>
                v.recurrence?.recurringEventId ===
                  visit?.recurrence.recurringEventId &&
                v.start.toDate() >= visit?.start.toDate()
            )
            .map(([id, v]) => ({ ...v, id }))
            .sort((a, b) => a.start.toDate() - b.start.toDate()) // Sorting the visits
        : [{ ...visit, id: visitId }]; // Fallback to only the current visit if non-recurring

    console.log("futureVisits: ", futureVisits);

    for (const visitItem of futureVisits) {
      const updateData = {
        [`employees.${currentEmployeeId}.responseStatus`]: "accepted",
        expiration: null,
        employeeArr: arrayUnion(currentEmployeeId), // Add employeeId to the employeeArr array
        hideEmployeeArr: arrayRemove(currentEmployeeId), // Remove currentEmployeeId from hideEmployeeArr
      };

      console.log("updateData: ", updateData);

      if (confirmationType === "all_visits") {
        updateData[`employees.${currentEmployeeId}.belongsToSeries`] = true;
      }

      // Update responseStatus and declineReason in Firestore for each future visit
      const visitItemDocRef = doc(db, "visits", visitItem.id);
      await updateDoc(visitItemDocRef, updateData);
      handleAccept({ employeeId: currentEmployeeId, visitId: visitItem.id });

      // Trigger GCF that removes the expiration
      // acceptVisit({ visitId: visitItem.id }).catch((error) => {
      //   console.error("Error in acceptingVisit:", error);
      // });

      // Break the loop if it's only for the current visit
      if (confirmationType !== "all_visits" && visitItem.id === visitId) {
        break;
      }
    }

    // Increase the employee accept streak.
    await incrementStreak(currentEmployeeId, "visitsAccepted");

    const hoursSinceCreationOrUpdate = Math.min(
      DateTime.now().diff(DateTime.fromJSDate(visit?.created.toDate()), "hours")
        .hours,
      visit?.updated
        ? DateTime.now().diff(
            DateTime.fromJSDate(visit.updated.toDate()),
            "hours"
          ).hours
        : Infinity
    );

    // Increment early response streak if the employee responds within 48 hours or the visit is 14+ days away, otherwise reset it.
    if (
      hoursSinceCreationOrUpdate < 48 ||
      DateTime.fromJSDate(visit?.start.toDate()).diff(DateTime.now(), "days")
        .days >= 14
    ) {
      await incrementStreak(currentEmployeeId, "respondingEarly");
    } else {
      await resetStreak(currentEmployeeId, "respondingEarly");
    }
  } catch (error) {
    // Handle errors here
    showSnackbar("Error accepting visit. Try again", "error");
    console.error("Error processing accept:", error);
  } finally {
    setLoading(false); // Reset the flag regardless of the outcome
  }
};

export const createEmployee = async (data) => {
  const functions = getFunctions();
  const createEmployeeFunction = httpsCallable(functions, "createEmployee");
  try {
    const result = await createEmployeeFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createEmployee function:", error);
    throw error;
  }
};

// export const declineVisit = async (data) => {
//   const functions = getFunctions();
//   const declineVisitFunction = httpsCallable(functions, "declineVisit");
//   try {
//     const result = await declineVisitFunction(data);
//     return result.data;
//   } catch (error) {
//     console.error("Error calling declineVisit function:", error);
//     throw error;
//   }
// };
export const pickupVisit = async ({
  employeeId,
  availability,
  visitId,
  employeeData,
  visitData,
  confirmationType,
  responseStatus,
}) => {
  console.log("Starting pickup Visit");

  // Ensure required parameters are provided
  if (!employeeId || !availability || !visitId || !employeeData || !visitData) {
    throw new Error(
      "Missing required arguments: employeeId, availability, visitId, employeeData, or visitData."
    );
  }

  try {
    // Reference to the visit document in Firestore
    const visitRef = doc(db, "visits", visitId);

    // Fetch the visit document
    const visitDoc = await getDoc(visitRef);
    if (!visitDoc.exists()) {
      throw new Error("The specified visit does not exist.");
    }

    const visit = visitDoc.data();

    // Check if employeeId is already in employeeArr
    if (visitData.employeeArr?.includes(employeeId)) {
      console.log("Employee has already accepted this visit");
      return { result: "Employee has already accepted this visit" };
    }

    // Check if the visit still needs employees
    if (visit.employeesNeeded <= 0) {
      console.log("Visit has already been taken");
      return { result: "Visit has already been taken" };
    }

    // Prepare the updated employee data
    const newEmployeeData = {
      comments: "",
      rating: null,
      responseStatus,
      updated: serverTimestamp(), // Current server time
      belongsToSeries: confirmationType === "all_visits" ? true : false,
      rate: null, // Logic to retrieve/set this value based on employee data
      bonus: null, // Add logic if you want to set this based on conditions
      avatarUrl: employeeData.avatarUrl, // Assuming avatarUrl is passed in employeeData
      displayName: `${employeeData.firstName} ${employeeData.lastName.charAt(
        0
      )}.`, // Formatting the name
    };

    // Prepare the data for updating the visit document
    let updateData = {
      [`employees.${employeeId}`]: newEmployeeData,
      employeeArr: arrayUnion(employeeId), // Add employeeId to the employeeArr array
      employeesNeeded: increment(-1), // Decrement the number of needed employees
      expiration: null, // Reset expiration when picked up
      hideEmployeeArr: arrayRemove(employeeId), // Remove currentEmployeeId from hideEmployeeArr
    };

    if (confirmationType === "all_visits") {
      updateData["seriesEmployeesNeeded"] = increment(-1);
    }

    // If there was only 1 employee needed, close the visit (set isOpenVisit to false)
    if (visitData.employeesNeeded === 1) {
      updateData.isOpenVisit = false;
    }

    // Update the visit document with the new employee data and isOpenVisit status
    await updateDoc(visitRef, updateData);

    console.info("Visit picked up successfully");
    return { result: "The visit was accepted successfully" };
  } catch (error) {
    console.error("An error occurred while accepting the visit:", error);
    throw new Error("An error occurred while accepting the visit.");
  }
};

// export const pickupVisit = async (data) => {
//   const functions = getFunctions();
//   const pickupVisitFunction = httpsCallable(functions, "pickupVisit");
//   try {
//     const result = await pickupVisitFunction(data);
//     return result.data;
//   } catch (error) {
//     console.error("Error calling pickupVisit function:", error);
//     throw error;
//   }
// };

export const acceptVisit = async (data) => {
  const functions = getFunctions();
  const acceptVisitFunction = httpsCallable(functions, "acceptVisit");
  try {
    const result = await acceptVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling acceptVisit function:", error);
    throw error;
  }
};

export const subtractAndMergeWindows = ({ availability, removeWindow }) => {
  // Convert the availability map to an array of objects, each containing the window and its corresponding key
  const availabilityArray = Object.keys(availability).map((key) => ({
    key,
    window: {
      start: moment.unix(availability[key].start.seconds),
      end: moment.unix(availability[key].end.seconds),
    },
  }));

  // Convert removeWindow's timestamps to moment objects for easy comparison
  const removeWindowConverted = {
    start: moment(removeWindow.start),
    end: moment(removeWindow.end),
  };

  const resultWindows = {};

  availabilityArray.forEach(({ key, window }) => {
    if (
      window.end.isBefore(removeWindowConverted.start) ||
      window.start.isAfter(removeWindowConverted.end)
    ) {
      // If the current window is completely outside the removing window, keep it unchanged.
      resultWindows[key] = {
        start: window.start.toDate(), // converting back to Date to be consistent with the return format
        end: window.end.toDate(),
      };
    } else {
      // If there's any kind of overlap, we're considering this as a "change" in the window,
      // so it should get a new UID regardless of the case.

      if (
        window.start.isBefore(removeWindowConverted.start) &&
        window.end.isAfter(removeWindowConverted.end)
      ) {
        // The removing window is entirely within the current window, split it.
        const newKeyFirstHalf = uuidv4();
        const newKeySecondHalf = uuidv4();

        resultWindows[newKeyFirstHalf] = {
          start: window.start.toDate(),
          end: removeWindowConverted.start.toDate(),
        };

        resultWindows[newKeySecondHalf] = {
          start: removeWindowConverted.end.toDate(),
          end: window.end.toDate(),
        };
      } else {
        // Partial overlap cases.
        const newKey = uuidv4(); // New UID for the changed window.

        if (window.start.isBefore(removeWindowConverted.start)) {
          resultWindows[newKey] = {
            start: window.start.toDate(),
            end: removeWindowConverted.start.toDate(),
          };
        } else if (window.end.isAfter(removeWindowConverted.end)) {
          resultWindows[newKey] = {
            start: removeWindowConverted.end.toDate(),
            end: window.end.toDate(),
          };
        }
        // If the removing window completely covers the current window, it gets removed,
        // so we don't add anything to the results.
      }
    }
  });

  // Convert back to the required format with Timestamps if necessary.
  const finalResult = {};
  for (const [key, window] of Object.entries(resultWindows)) {
    finalResult[key] = {
      start: Timestamp.fromDate(new Date(window.start)), // Assuming Timestamp.fromDate takes a Date object
      end: Timestamp.fromDate(new Date(window.end)),
    };
  }

  return finalResult;
};

export const addAndMergeWindows = ({ availability, addWindow }) => {
  console.time("addAndMergeWindowsExecutionTime"); // Start timing here

  console.log("addWindow: ", addWindow);
  // Convert the availability map to an array of objects, each containing the window and its corresponding key
  const availabilityArray = Object.keys(availability).map((key) => ({
    key,
    window: {
      start: moment.unix(availability[key].start.seconds),
      end: moment.unix(availability[key].end.seconds),
    },
  }));

  // Convert addWindow's timestamps to moment objects for easy comparison
  const addWindowConverted = {
    key: uuidv4(), // Assign a new UID right away for the new window
    window: {
      start: moment(addWindow.start),
      end: moment(addWindow.end),
    },
  };

  // Add the new window to the array
  availabilityArray.push(addWindowConverted);

  // Sort the windows by their start time
  availabilityArray.sort(
    (a, b) => a.window.start.valueOf() - b.window.start.valueOf()
  );

  const mergedWindows = [];
  let previousWindow = availabilityArray[0];

  // Start merging windows
  for (let i = 1; i < availabilityArray.length; i++) {
    const current = availabilityArray[i];

    if (previousWindow.window.end.isSameOrAfter(current.window.start)) {
      // Overlapping windows need to be merged
      const newStart = previousWindow.window.start;
      const newEnd = moment.max(previousWindow.window.end, current.window.end);

      previousWindow = {
        key: uuidv4(), // Assigning a new UID since we're creating a new merged window
        window: {
          start: newStart,
          end: newEnd,
        },
      };
    } else {
      // No overlap, so we can add the previous window to our results
      mergedWindows.push(previousWindow);
      previousWindow = current;
    }
  }

  // Push the last window after finishing the loop
  mergedWindows.push(previousWindow);

  // Construct the final result with the Timestamps
  const resultWindows = {};
  for (const { key, window } of mergedWindows) {
    resultWindows[key] = {
      start: Timestamp.fromDate(window.start.toDate()),
      end: Timestamp.fromDate(window.end.toDate()),
    };
  }

  console.timeEnd("addAndMergeWindowsExecutionTime"); // End timing here

  return resultWindows;
};

export const addEmployeeRole = async (data) => {
  const functions = getFunctions();
  const addEmployeeRoleFunction = httpsCallable(functions, "addEmployeeRole");
  try {
    const result = await addEmployeeRoleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addEmployeeRole function:", error);
    throw error;
  }
};

export const updateEmployeeLocation = async (data) => {
  const functions = getFunctions();
  const updateEmployeeLocationFunction = httpsCallable(
    functions,
    "updateEmployeeLocation"
  );
  try {
    const result = await updateEmployeeLocationFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateEmployeeLocation function:", error);
    throw error;
  }
};
