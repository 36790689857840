import { Check } from "@mui/icons-material";
import OddJobsIcon from "@mui/icons-material/Build";
import OrganizationIcon from "@mui/icons-material/Category";
import DecorationIcon from "@mui/icons-material/Celebration";
import FurnitureIcon from "@mui/icons-material/Chair";
import CleaningIcon from "@mui/icons-material/CleaningServices";
import TechHelpIcon from "@mui/icons-material/Computer";
import HeavyLiftingIcon from "@mui/icons-material/FitnessCenter";
import GarageHelpIcon from "@mui/icons-material/Garage";
import YardWorkIcon from "@mui/icons-material/Grass";
import GardeningIcon from "@mui/icons-material/LocalFlorist";
import CompanionshipIcon from "@mui/icons-material/People";
import ErrandsIcon from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

const tasks = [
  {
    title: "Gardening",
    icon: <GardeningIcon />,
    description: "Transform your garden with an extra hand",
    examples: ["Weed garden beds", "Plant flowers", "Water plants"],
  },
  {
    title: "Yard Work",
    icon: <YardWorkIcon />,
    description: "Keep your yard in check with regular upkeep",
    examples: ["Spread bark", "Rake leaves", "Prune bushes"],
  },
  {
    title: "Cleaning",
    icon: <CleaningIcon />,
    description: "Give your home a chance to sparkle",
    examples: ["Scrub cabinets", "Vacuum carpets", "Mop floors"],
  },
  {
    title: "Garage Help",
    icon: <GarageHelpIcon />,
    description: "Reclaim extra space in your garage",
    examples: [
      "Move heavy boxes",
      "Organize storage",
      "Clean nooks and crannies",
    ],
  },
  {
    title: "Organization",
    icon: <OrganizationIcon />,
    description: "Declutter those stubborn areas",
    examples: ["Organize closets", "Declutter rooms", "Sort through boxes"],
  },
  {
    title: "Heavy Lifting",
    icon: <HeavyLiftingIcon />,
    description: "Save your back while we move heavy items",
    examples: ["Re-arrange furniture", "Carry heavy boxes", "Move rugs"],
  },
  {
    title: "Tech Help",
    icon: <TechHelpIcon />,
    description: "Solve your tech problems with our savvy help",
    examples: [
      "Setup TV streaming",
      "Computer and phone help",
      "Connect printer",
    ],
  },
  {
    title: "Decoration",
    icon: <DecorationIcon />,
    description: "Celebrate festivities with seasonal decorations",
    examples: ["Setup Christmas tree", "String lights", "Hang artwork"],
  },
  {
    title: "Errands",
    icon: <ErrandsIcon />,
    description: "Free your time by letting us run basic errands",
    examples: ["Grocery shop", "Pick up flowers", "Mail packages"],
  },
  {
    title: "Furniture",
    icon: <FurnitureIcon />,
    description: "Get help with furniture assembly and moving.",
    examples: ["Assemble storage racks", "Move couch", "Clean arm chair"],
  },
  {
    title: "Companion",
    icon: <CompanionshipIcon />,
    description: "Enjoy friendly company and conversation.",
    examples: ["Chat over coffee", "Play board games", "Go for walks"],
  },
  {
    title: "Odd Jobs",
    icon: <OddJobsIcon />,
    description: "Handle small tasks around the house with ease.",
    examples: [
      "Winterize deck furniture",
      "Change light bulbs",
      "Re-pot plants",
    ],
  },
];

const ServicesGrid = ({ expanded = true, pageName = "", listOnly = false }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"services overview"`); // Pass the logMessage here

  const renderTask = (task) => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Icon>{task.icon}</Icon>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: listOnly ? "14px" : expanded ? "20px" : "14px",
                sm: listOnly ? "14px" : "20px",
              },
              fontWeight: listOnly ? 500 : 800,
            }}
          >
            {task.title}
          </Typography>
        </Box>
        {expanded && (
          <Typography variant="body2" color="text.secondary">
            {task.description}
          </Typography>
        )}
        {expanded && (
          <List dense>
            {task.examples.map((example, idx) => (
              <ListItem key={idx} disableGutters disablePadding>
                <Check color="primary" sx={{ mr: 1 }} />
                <ListItemText primary={example} />
              </ListItem>
            ))}
          </List>
        )}
      </>
    );
  };

  return (
    <Box
      ref={ref}
      sx={{
        flexGrow: 1,
        padding: { xs: 0, sm: listOnly ? 0 : 2 },
        maxWidth: "1200px",
        width: "100%",
        mx: "auto",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: listOnly
            ? {
                xs: "repeat(2, 1fr)",
                sm: "repeat(auto-fill, minmax(175px, 1fr))",
              }
            : expanded
            ? "repeat(auto-fill, minmax(250px, 1fr))" // 1 column for expanded on mobile
            : {
                xs: "repeat(2, 1fr)",
                sm: "repeat(auto-fill, minmax(250px, 1fr))",
              }, // 2 columns on mobile, default on larger screens
          gap: { xs: listOnly ? 0 : 1, sm: listOnly ? 0 : 2 },
          padding: { xs: 0, sm: listOnly ? 0 : 2 },
          boxSizing: "border-box",
        }}
      >
        {tasks.map((task, index) => (
          <Box
            sx={{
              padding: { xs: listOnly ? 1 : 2, sm: listOnly ? 1 : 4 },
              backgroundColor: listOnly ? "transparent" : "background.paper",
              borderRadius: 1,
              border: listOnly ? "none" : "1px solid rgba(0,0,0,0.1)",
              boxShadow: listOnly
                ? "transparent"
                : "rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 3px 6px",
              // height: "100%",
            }}
          >
            {renderTask(task)}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ServicesGrid;
