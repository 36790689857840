import {
  Timestamp,
  collection,
  doc,
  endAt,
  getDoc,
  increment,
  onSnapshot,
  orderBy,
  query,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import { distanceBetween, geohashQueryBounds } from "geofire-common"; // make sure you have 'geofire-common' installed
import { DateTime } from "luxon"; // Import Luxon for date manipulation
import moment from "moment-timezone";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingUser from "../components/LoadingUser";
import { auth, db } from "../firebase";
import { decodeGeoHash, isPointInPolygon } from "../services/locationServices";
import { useAuth } from "./AuthProvider";

const EmployeeContext = createContext({
  data: null,
  loading: true,
  setData: () => {}, // empty function for the initial value
  setLoading: () => {}, // empty function for the initial value
});

const EmployeeProvider = ({ children }) => {
  const {currentUser} = useAuth()

  const [data, setData] = useState({});

  const [userPrivateData, setUserPrivateData] = useState(null);
  const [userPrivateLoading, setUserPrivateLoading] = useState(true);

  const [loading, setLoading] = useState(true); // Initial loading state
  const [currentDayWithVisits, setCurrentDayWithVisits] = useState();

  const [memberUnsubscribes, setMemberUnsubscribes] = useState({});
  // Ref to keep track of visit unsubscribe functions
  const visitUnsubscribesRef = useRef({});
  const memberUnsubscribesRef = useRef({}); // Ref to keep track of member unsubscribe functions
  const employeeUnsubscribesRef = useRef({});

  // AUTH // EMPLOYEE // EMPLOYEE.PRIVATE for USER
  useEffect(() => {
    // console.log(
    //   "------------------------- init snapshot listener: auth ---------------------------"
    // );
    const authUnsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (!userAuth) {
        setData(null);
        setLoading(false);
        return;
      }

      const employeeDocRef = doc(db, "employees", userAuth.uid);
      let privateUnsubscribe;

      try {
        const employeeUnsubscribe = onSnapshot(
          employeeDocRef,
          async (employeeDoc) => {
            if (employeeDoc.exists()) {
              const employeeData = employeeDoc.data();

              // console.log(
              //   "------------------------- init snapshot listener: employee ---------------------------"
              // );

              // Store employee data
              setData((prevData) => ({
                ...prevData,
                auth: userAuth,
                employee: employeeData,
              }));

              // Set up listener for the "private" sub-collection
              const privateColRef = collection(employeeDocRef, "private");
              privateUnsubscribe = onSnapshot(
                privateColRef,
                (privateSnapshot) => {
                  const allPrivateData = {};
                  privateSnapshot.docs.forEach((docSnapshot) => {
                    const privateDocId = docSnapshot.id;
                    allPrivateData[privateDocId] = docSnapshot.data();
                  });

                  setData((prevData) => ({
                    ...prevData,
                    employee: {
                      ...prevData.employee,
                      private: allPrivateData,
                    },
                  }));
                }
              );
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );

        return () => {
          employeeUnsubscribe(); // Cleanup the employee listener
          if (privateUnsubscribe) privateUnsubscribe(); // Cleanup the private sub-collection listener
        };
      } catch (error) {
        console.error("Error setting up snapshot listener:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    });

    // Cleanup the authentication listener
    return () => {
      authUnsubscribe();

      // prevent memory leaks? - not necessary I think
      // setData(prevData => ({ ...prevData, employee: { ...prevData.employee, visits: [] } }));

      // console.log("++++++++++++ UNMOUNT EMPLOYEE PROVIDER ++++++++++++")
    };
  }, []);

  // Login streak
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        if (currentUser?.uid) {
          // Reference to the employee document
          const employeePrivateDocRef = doc(
            db,
            "employeesPrivate",
            currentUser?.uid
          );

          // Fetch the employee document from Firestore
          const employeePrivateDoc = await getDoc(employeePrivateDocRef);

          const employeePrivateData = employeePrivateDoc.data();

          // Call handleCheckInStreak after fetching employee data
          handleCheckInStreak(
            employeePrivateData?.progress?.streaks?.appCheckIn?.lastWeekDate,
            currentUser?.uid
          );

          // Update lastActive to now
          await updateDoc(doc(db, "employees", currentUser?.uid), {
            "admin.lastActive": Timestamp.now(),
          });
        }
      } catch (error) {
        console.error("Error fetching employee data: ", error);
      }
    };

    fetchEmployeeData(); // Trigger the fetch on component mount
  }, [currentUser?.uid]);

  const handleCheckInStreak = async (lastWeekDate, employeeId) => {
    const now = DateTime.now().startOf("day"); // Get today's date, normalized

    // If lastWeekDate is undefined, initialize the streak
    if (!lastWeekDate) {
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": 1,
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
      return;
    }

    const lastWeekDateTime = DateTime.fromJSDate(lastWeekDate.toDate()).startOf(
      "day"
    ); // Convert to Luxon DateTime and normalize
    const daysSinceLastWeek = now.diff(lastWeekDateTime, "days").days;

    if (daysSinceLastWeek >= 7 && daysSinceLastWeek < 14) {
      // Increment the streak if last active was between 7 and 14 days ago
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": increment(1),
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
    } else if (daysSinceLastWeek >= 14) {
      // Reset the streak if last active was more than 14 days ago
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": 1,
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
    }
  };

  // Subscription to usersPrivate collection
  useEffect(() => {
    if (currentUser?.uid) {
      const unsubscribe = onSnapshot(
        doc(db, "employeesPrivate", currentUser?.uid),
        (doc) => {
          if (doc.exists()) {
            setUserPrivateData(doc.data());
          }
          setUserPrivateLoading(false); // Indicate loading is done
        }
      );

      // Cleanup function
      return () => unsubscribe();
    } else {
      setUserPrivateLoading(false); // Ensure loading is set to false if there's no currentUser
    }
  }, [currentUser?.uid]); // Similar dependency but handled separately

  // Queried VISITS for CURRENT USER
  useEffect(() => {
    // Get the user's UID. Here it's assumed you have access to this information, perhaps through context, props, or a global state.
    const userUID = currentUser?.uid;

    if (!userUID) return;

    console.log("userUID:", userUID);

    // Define a reference to the 'visits' collection.
    const visitsRef = collection(db, "visits");

    const visitsQuery = query(
      visitsRef,
      where("employeeArr", "array-contains", userUID), // Checks if the user's UID is in the 'employeeArr' array
      where("status", "==", "confirmed") // Filter visits where status is "confirmed"
    );

    // Attach a listener for this query.
    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      const visits = {};
      querySnapshot.forEach((doc) => {
        // Store the visit data in the 'visits' object using the visit ID as the key.
        visits[doc.id] = doc.data();
      });

      // Update the state with the new visits data.
      setData((prevData) => ({
        ...prevData,
        visits,
      }));
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [currentUser?.uid]);

  // Queried GEOHASH LOCAL OPEN VISITS for CURRENT USER by POLYGON FIT
  useEffect(() => {
    const employeeData = data?.employee;
    const maxTravelMiles = data?.employee?.maxTravelMiles;
    const userUID = currentUser?.uid;
    const geohash = employeeData?.location?.geohash;

    // Check if employee data is loaded
    if (!employeeData) {
      return;
    }

    // Further checks
    if (!geohash || !maxTravelMiles || data?.employee?.status === "applicant") {
      return;
    }

    console.log("data?.employee?.status: ", data?.employee?.status);

    const center = decodeGeoHash(geohash);
    const radiusInM = 10 * 1609.34; // conversion from miles to meters

    const bounds = geohashQueryBounds(center, radiusInM);
    console.log("Employee Geoquery Open Visits Bounds: ", bounds);

    // We are using a Map here to benefit from its methods and to avoid duplicate visits
    const allVisits = new Map();

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "visits"),
        where("isOpenVisit", "==", true),
        where("status", "==", "confirmed"),
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const visitData = change.doc.data();

            // Skip visits where the employee's ID is in the hideEmployeeArr
            // if (
            //   visitData.hideEmployeeArr &&
            //   visitData.hideEmployeeArr.includes(userUID)
            // ) {
            //   return; // Skip this iteration
            // }

            const visitCoords = decodeGeoHash(visitData?.location?.geohash);

            const visitDistance =
              distanceBetween(center, visitCoords) * 0.621371; // to miles

            // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
            // Assuming memberCoordinates are a { lat, lng } pair
            if (employeeData?.location?.bounds !== undefined) {
              if (isPointInPolygon(visitCoords, employeeData.location.bounds)) {
                allVisits.set(change.doc.id, visitData); // Accumulating visits from all bounds
              }
            } else if (visitDistance <= maxTravelMiles) {
              console.log("adding open visit: ", change.doc.id);
              allVisits.set(change.doc.id, visitData); // Accumulating visits from all bounds
            }
          } else if (change.type === "removed") {
            // Employee has been removed from the query results, remove them from the Map
            console.log("removing open visit: ", change.doc.id);
            allVisits.delete(change.doc.id);
          }
        });

        // Convert the Map to a regular object to update the state
        const aggregatedVisits = Object.fromEntries(allVisits);

        // Update state with all aggregated visits
        setData((prevData) => ({
          ...prevData,
          openVisits: aggregatedVisits,
        }));
      });
    });

    // Cleanup function
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [data?.employee?.location, data?.employee?.maxTravelMiles]); // Dependency array

  // MEMBERS associated with DATA.VISITS and OPEN VISITS
  useEffect(() => {
    // Extract member IDs from the visits.
    const memberIdsFromVisits = Object.values(data?.visits || {}).map(
      (visit) => visit.member
    );

    // Extract member IDs from the open visits.
    const memberIdsFromOpenVisits = Object.values(data?.openVisits || {}).map(
      (visit) => visit.member
    );

    // Combine the two arrays into one using the spread operator and then create a Set from the combined array.
    // This will automatically remove any duplicates because sets only allow unique values.
    const uniqueMemberIdsSet = new Set([
      ...memberIdsFromVisits,
      ...memberIdsFromOpenVisits,
    ]);

    // If you need to work with an array later on, you can convert the set back to an array.
    // However, this step is unnecessary if you're okay with working with the set directly.
    const uniqueMemberIds = Array.from(uniqueMemberIdsSet);

    // Calculate new member IDs to add listeners for
    const idsToAdd = uniqueMemberIds.filter(
      (id) => !memberUnsubscribesRef.current[id]
    );
    // console.log("idsToAdd", idsToAdd);

    // Subscribe to public data for new members
    idsToAdd.forEach((id) => {
      const memberDocRef = doc(db, "members", id);

      const publicUnsub = onSnapshot(memberDocRef, (memberDoc) => {
        if (memberDoc.exists()) {
          setData((prevData) => ({
            ...prevData,
            members: {
              ...prevData.members,
              [id]: memberDoc.data(), // public data
            },
          }));
        }
      });

      // We store each public unsubscribe function with the key 'public' for clarity
      memberUnsubscribesRef.current[id] =
        memberUnsubscribesRef.current[id] || {};
      memberUnsubscribesRef.current[id].public = publicUnsub;
    });

    // Cleanup function
    return () => {
      // Find IDs to remove
      const idsToRemove = Object.keys(memberUnsubscribesRef.current).filter(
        (id) => !uniqueMemberIds.includes(id)
      );
      console.log("========================== idsToRemove: ", idsToRemove);
      idsToRemove.forEach((id) => {
        // Unsubscribe from public, private, and account listeners separately
        const unsubObj = memberUnsubscribesRef.current[id];
        if (unsubObj) {
          ["public", "private", "account"].forEach((key) => {
            if (unsubObj[key]) {
              unsubObj[key](); // Call the unsubscribe function
            }
          });
        }

        console.log("unsubed from: ", id);

        // Clean up our unsubscribe store
        delete memberUnsubscribesRef.current[id];
      });

      // Here is where we remove the member data from our state
      if (idsToRemove.length > 0) {
        setData((prevData) => {
          const newMembers = { ...prevData.members };
          idsToRemove.forEach((id) => {
            delete newMembers[id]; // remove the member data from the state
          });
          return { ...prevData, members: newMembers }; // return the new state
        });
      }
      // console.log(
      // "========================== UNSUB FROM REMOVED MEMBERS ========================== "
      // );
    };
  }, [data?.visits, data?.openVisits]);

  // EMPLOYEES associated with ALL VISITS and LOOKUP
  useEffect(() => {
    // Extract employee IDs from ALL VISITS
    const employeeIdsFromVisits = Object.values(data?.visits || {})
      .map((visit) => Object.keys(visit.employees || {}))
      .flat();

    // Extract employee IDs from LOOKUP array
    const employeeIdsFromLookup = data?.lookup?.employeeIds || [];

    // Combine both lists and remove any duplicates.
    const uniqueEmployeeIds = Array.from(
      new Set([...employeeIdsFromVisits, ...employeeIdsFromLookup])
    );

    // Calculate new employee IDs to add listeners for
    const idsToAdd = uniqueEmployeeIds.filter(
      (id) => !employeeUnsubscribesRef.current[id]
    );

    idsToAdd.forEach((id) => {
      const employeeDocRef = doc(db, "employees", id);

      const newUnsub = onSnapshot(employeeDocRef, (employeeDoc) => {
        if (employeeDoc.exists()) {
          setData((prevData) => ({
            ...prevData,
            employees: {
              ...prevData.employees,
              [id]: employeeDoc.data(),
            },
          }));
        }
      });

      // Store the new unsubscribe function in the ref
      employeeUnsubscribesRef.current[id] = newUnsub;
    });

    // Cleanup function
    return () => {
      // Find IDs to remove
      const idsToRemove = Object.keys(employeeUnsubscribesRef.current).filter(
        (id) => !uniqueEmployeeIds.includes(id)
      );
      idsToRemove.forEach((id) => {
        employeeUnsubscribesRef.current[id](); // Call the unsubscribe function
        delete employeeUnsubscribesRef.current[id]; // Remove it from the current list
      });
    };
  }, [data?.visits]);

  useEffect(() => {
    if (!data?.employee?.timeZoneId || (!data?.visits && !data?.openVisits))
      return;

    const timeZoneId = data.employee.timeZoneId;
    const now = moment().tz(timeZoneId); // Get the current time with the correct timezone
    const startOfToday = now.clone().startOf("day"); // Clone now before using startOf("day")

    // Find the earliest visit starting from today
    const futureVisits = Object.values(data.visits || {})
      .map((visit) => moment(visit.start.toDate()).tz(timeZoneId))
      .filter((visitStart) => visitStart.isSameOrAfter(startOfToday));

    // Sort and get the earliest future visit
    const earliestFutureVisit = futureVisits.sort((a, b) => a.diff(b))[0];

    if (earliestFutureVisit) {
      // If a future visit is found, use that day
      setCurrentDayWithVisits(earliestFutureVisit.startOf("day"));
    } else if (data?.openVisits) {
      // If no future visits, try to find the earliest open visit
      const futureOpenVisits = Object.values(data.openVisits || {})
        .map((openVisit) => moment(openVisit.start.toDate()).tz(timeZoneId))
        .filter((openVisitStart) => openVisitStart.isAfter(now)); // Ensure open visit is after the current time

      // Sort and get the earliest open visit
      const earliestOpenVisit = futureOpenVisits.sort((a, b) => a.diff(b))[0];

      if (earliestOpenVisit) {
        setCurrentDayWithVisits(earliestOpenVisit.startOf("day"));
      } else {
        setCurrentDayWithVisits(null); // No future visits or open visits found
      }
    } else {
      // No future visits and no open visits, set to null
      setCurrentDayWithVisits(null);
    }
  }, [data?.visits, data?.openVisits, data?.employee?.timeZoneId]);

  // POLYGON EVENTS nearby - Use effect to find nearby events
  useEffect(() => {
    const employeeLocationHash = data?.employee?.location?.geohash;
    if (!employeeLocationHash) return;

    const employeeGeohashCoordinates = decodeGeoHash(employeeLocationHash);
    console.log("employeeGeohashCoordinates: ", employeeGeohashCoordinates);
    const radiusInM = 10 * 1609.34; // 10 miles in meters, adjust as needed

    const bounds = geohashQueryBounds(employeeGeohashCoordinates, radiusInM);

    const matchedAddress = data?.employee?.private?.data?.address[0];

    if (!matchedAddress) return;

    const employeeCoordinates = {
      lat: matchedAddress.lat,
      lng: matchedAddress.lng,
    };
    console.log("employeeCoordinates: ", employeeCoordinates);

    const eventsRef = collection(db, "events"); // Adjust according to your events collection name
    const eventUnsubscribes = [];

    bounds.forEach(([start, end]) => {
      const eventsQuery = query(
        eventsRef,
        where("status", "==", "active"), // Filter events where status is "active"
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
        const newEvents = {};
        querySnapshot.forEach((doc) => {
          const eventData = doc.data();

          console.log("employeeCoordinates: ", employeeCoordinates);
          console.log("eventData.location.bounds: ", eventData.location.bounds);

          // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
          if (
            isPointInPolygon(employeeCoordinates, eventData.location.bounds)
          ) {
            console.log("is within bounds");
            newEvents[doc.id] = eventData;
          }
        });

        // Update the state with new events within maxDistance
        setData((prevData) => ({
          ...prevData,
          events: { ...prevData.events, ...newEvents },
        }));
      });

      eventUnsubscribes.push(unsubscribe);
    });

    // Cleanup function
    return () => {
      eventUnsubscribes.forEach((unsub) => unsub());
    };
  }, [
    data?.employee?.location?.geohash,
    data?.employee?.private?.data?.address?.[0] ?? null,
  ]); // Dependency array

  // useEffect hook to watch for changes in the user state
  useEffect(() => {
    if (data && data.employee) {
      console.log("Updated data:", data);
    }
  }, [data]);

  // Conditionally render the LoadingUser component if loading
  if (loading) {
    // If loading is true, render the LoadingUser component
    return <LoadingUser loading={loading} />;
  }

  return (
    <EmployeeContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        currentDayWithVisits,
        userPrivateData,
        userPrivateLoading,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeProvider;

export const useEmployee = () => {
  return useContext(EmployeeContext);
};
