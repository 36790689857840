import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import imageCompression from "browser-image-compression";
import { updateProfile } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, storage } from "../firebase"; // Import your Firebase auth and storage instance

// Define a styled component for the dropzone area
const DropzoneArea = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: theme.palette.background.default,
}));

const DividerWithText = (props) => {
  return (
    <Box display="flex" alignItems="center" my={2}>
      <Box flex={1} minWidth="30px">
        <Divider />
      </Box>
      <Typography variant="body2" component="span" color="textSecondary" mx={2}>
        {props.children}
      </Typography>
      <Box flex={1} minWidth="30px">
        <Divider />
      </Box>
    </Box>
  );
};

function AvatarUpload({ avatarUrl, onAvatarUpload, externalUid }) {
  const [firebaseUser] = useAuthState(auth);
  const [photoURL, setPhotoURL] = useState(avatarUrl); // Initialize with avatarUrl prop
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const theme = useTheme();

  console.log("avatarUrl: ", avatarUrl);
  console.log("photoURL: ", photoURL);

  // Use the externalUid if provided, otherwise default to the local user's UID
  const uid = externalUid || (firebaseUser && firebaseUser.uid);

  // This effect runs whenever the avatarUrl prop changes.
  useEffect(() => {
    // Check if the incoming avatarUrl is not null or undefined
    if (avatarUrl != photoURL && avatarUrl !== "") {
      setPhotoURL(avatarUrl); // Update the photoURL state
    }
  }, [avatarUrl]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // if (photoURL == "" || avatarUrl == "") {
      //   console.error("No file yet.");
      //   return;
      // }

      if (!firebaseUser) {
        console.error("No authenticated user available.");
        return;
      }

      const file = acceptedFiles[0];

      console.log("originalFile instanceof Blob", file instanceof Blob); // true
      console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

      // Compress the image file
      const options = {
        maxSizeMB: 0.2, // (max file size in MB, adjust as needed)
        maxWidthOrHeight: 600, // (compressed file's maximum dimension in pixels)
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);

        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        // Proceed with the upload...
        const metadata = {
          contentType: compressedFile.type,
        };

        // Use ref to get a reference to the storage service
        const storageRef = ref(storage, "avatars/" + uid);
        const uploadTask = uploadBytesResumable(
          storageRef,
          compressedFile,
          metadata
        );

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // The state_changed observer includes events for progress monitoring
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error("Upload failed:", error);
          },
          () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              updateProfile(firebaseUser, {
                photoURL: downloadURL,
              })
                .then(() => {
                  console.log("User profile updated!");
                  setPhotoURL(downloadURL); // Update the local photoURL state

                  onAvatarUpload(downloadURL); // Call onAvatarUpload prop to pass URL up to parent
                })
                .catch((error) => {
                  console.error("Error updating user profile:", error);
                });
            });
          }
        );
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    },
    [firebaseUser, onAvatarUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  return (
    <>
      <DropzoneArea
        {...getRootProps()}
        sx={{
          width: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
          backgroundColor: isDragActive
            ? theme.palette.primary.lightest
            : "background.default", // Change background color on drag
          boxShadow: isDragActive ? "0px 0px 8px 2px rgba(0,0,0,0.2)" : "none", // Add shadow on drag
          transition: "background-color 0.3s, box-shadow 0.3s", // Add transition here
        }}
      >
        {" "}
        <input {...getInputProps()} />
        {uploadProgress > 0 && uploadProgress < 100 ? (
          <CircularProgress variant="determinate" value={uploadProgress} />
        ) : (
          <Avatar
            src={photoURL}
            alt="User Avatar"
            sx={{ width: 96, height: 96 }}
          />
        )}
        {/* Adjust the size here */}
        <Stack
          alignItems="center"
          spacing={1}
          sx={{ flexGrow: 1, justifyContent: "center", zIndex: 1 }}
        >
          <Typography variant="body2">Drag and drop</Typography>
          <DividerWithText>or</DividerWithText>
          <Button variant="outlined">Browse files</Button>{" "}
          {/* Make "Browse Files" a button */}
        </Stack>
      </DropzoneArea>
    </>
  );
}

export default AvatarUpload;
