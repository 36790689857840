import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import React, { useState } from "react";
import { auth, functions } from "../firebase";

import { keyframes } from "@emotion/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { sendSMSClient } from "../services/communicationServices";
import { extractPhoneNumber } from "../services/inputServices";
import { createLog } from "../services/logServices";
import { addAdminData, addMemberRole } from "../services/memberServices";
import NameField from "./fields/NameField";
import PhoneField from "./fields/PhoneField";

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MemberSignUp = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const nextUrl = "/dashboard";

  // const query = new URLSearchParams(window.location.search);
  // const referralParam = query.get("referral");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const validateStep = () => {
    let tempErrors = {};
    if (!values.firstName) tempErrors.firstName = "First Name is required";
    if (!values.lastName) tempErrors.lastName = "Last Name is required";
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";
    if (!values.termsAccepted || values.termsAccepted === false)
      tempErrors.termsAccepted = "You must accept the terms and conditions.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleLog = async () => {
    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      name: values?.firstName + " " + values?.lastName || "",
      phone: extractPhoneNumber(values?.phone || ""),
      email: values?.email || "",
      location: {
        zipCode: values?.zipCode || "",
      },
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: new Date(),
        contactedCount: 0,
        source: "created_account",
        timeline: {
          created_account: new Date(),
        },
        utmSource: utmSource || "",
      },
    };

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    // CREATE A LOGS DOCUMENT HERE WITH... /////////////////
    // - tasks: values?.tasks || [],
    // - message: values?.message || "",
    // - source: sign_up

    // const createLead = httpsCallable(functions, "createLead");
    // createLead({ ...filteredLeadData, sessionId: storedSessionId });

    // Save the lead to Firebase
    // await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
    //   merge: true,
    // });
    // console.log("Lead saved successfully");

    const created = DateTime.fromJSDate(leadData?.crm?.leadCreated)
      .setZone("America/Los_Angeles")
      .toFormat("ccc LLL dd, hh:mm a");

    const smsBody = `Source: created_account\nName: ${leadData?.name}\nPhone: ${leadData?.phone}\nEmail: ${leadData?.email}\n${created}`;

    sendSMSClient({
      phone: "3603366344",
      body: smsBody,
    });

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _createdAccount: new Date(),
            _name: values?.firstName + " " + values?.lastName || "",
            _source: "signup",
            _phone: values?.phone || "",
            _email: values?.email || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    localStorage.setItem("leadCaptured", "true");
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validateStep()) {
      setLoading(false);
      return;
    }

    const newError = {}; // initialize newError object here
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email || "unknown",
        values.password || "unknown"
      );
      const user = userCredential.user;

      // Usage with values.firstName and values.lastName
      const formattedFirstName = capitalizeFirstLetter(values.firstName);
      const formattedLastName = capitalizeFirstLetter(values.lastName);

      // Run updateProfile and addMemberRole in parallel
      await Promise.all([
        updateProfile(user, {
          displayName: `${formattedFirstName} ${formattedLastName}`,
        }),
        addMemberRole(),
      ]);

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);

      console.log("3");

      const announcements = [
        "welcome_to_linked_lives",
        "scheduling_visits",
        "tools_and_supplies",
        "services_overview",
        "monthly_billing",
      ];

      const notificationSettings = {
        bookingConfirmation: { email: true, sms: true },
        visitCancellations: { email: true, sms: true },
        visitUpdates: { email: true, sms: true },
        visitReminders: { email: true, sms: true },
        visitExpirations: { email: true, sms: true },
        visitRatings: { email: true, sms: true },
        newsletters: { email: true, sms: true },
        promotions: { email: true, sms: true },
      };
      console.log("4");

      // const now = Date.now();
      // const COUNTDOWN_DURATION = 3600; // 1 hour countdown in seconds
      // const firstVisited = localStorage.getItem("firstVisited") || 0;
      // const timePassed = Math.floor((now - firstVisited) / 1000);
      // const remainingTime = Math.max(COUNTDOWN_DURATION - timePassed, 0);
      // const freeHour = remainingTime > 0;

      // Data for addAdminData function
      const adminData = {
        termsAccepted: values.termsAccepted,
        // freeHour: freeHour,
        // referralParam,
      };

      console.log("5");

      const timeZoneId =
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        "America/Los_Angeles";

      // Create a document in Firestore for the member
      await Promise.all([
        // Setting the member's public data
        setDoc(doc(db, "members", user.uid), {
          avatarUrl: "",
          firstName: formattedFirstName,
          lastName: formattedLastName,
          created: Timestamp.now(),
          timeZoneId,
        }),

        // Setting the member's private data
        setDoc(
          doc(db, "membersPrivate", user.uid),
          {
            avatarUrl: "",
            firstName: formattedFirstName,
            lastName: formattedLastName,
            email: values.email,
            phone: values.phone,
            announcements,
            autoPay: true,
            hasLoggedIn: true,
            notifications: notificationSettings,
            created: Timestamp.now(),
            timeZoneId,
            billing: {
              nextMembershipFeeDate: null,
              nextInvoiceDate: null,
              lastInvoiceDate: Timestamp.now(),
            },
            admin: {
              createdBy: user?.uid,
            },
            crm: {
              lastDiscountDate: Timestamp.now(),
              lastVisitDate: false,
              lastContactedDate: false,
              followUpDate: Timestamp.now(),
              contactedCount: 0,
            },
          },
          { merge: true }
        ),

        // Creating or updating the account document using the user's UID
        setDoc(
          doc(db, "accounts", user.uid),
          {
            roles: { [user.uid]: "owner" }, // Assigning the role of 'owner' to the user
          },
          { merge: true }
        ),
      ]);

      const sendWelcomeMessage = httpsCallable(functions, "sendWelcomeMessage");
      sendWelcomeMessage({ uid: user.uid });

      await createLog({
        collectionName: "members",
        idsArr: [user.uid],
        summary: `${formattedFirstName} created an account online`,
      });

      addAdminData(adminData)
        .then((result) => {
          console.log("Admin data function call result:", result);
        })
        .catch((error) => {
          console.error("Error calling addAdminData function:", error);
        });

      // Update the lead form and session data
      await handleLog();

      navigate(nextUrl);
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={{ width: "auto" }}></Box>
      <Card
        elevation={0}
        sx={{
          px: { xs: "0", sm: 3 },
          py: 3,
          maxWidth: "400px",
          border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
          overflow: "visible",
          animation: `${fadeInMoveDown} 1s forwards`,
        }}
      >
        {/* {referralParam && (
          <Alert
            severity="success"
            sx={{
              mx: { xs: -2, sm: -3 },
              mt: { xs: -3, sm: -3 },
              mb: 2,
              borderRadius: 0,
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            You have been gifted a free 1-hour visit.
          </Alert>
        )} */}
        <Typography variant="h5" align="center" gutterBottom>
          Create Your Account
        </Typography>
        <Typography
          variant="body2"
          align="center"
          style={{ marginBottom: "1em" }}
        >
          Already have an account?{" "}
          <Link
            to="/member/login"
            style={{ color: "inherit", textDecoration: "underline" }}
          >
            Login
          </Link>
        </Typography>
        {/* <Box sx={{ mx: { xs: -1, sm: -1 }, my: 2 }}>
          <CountdownBar
            showButton={false}
            showLabels={false}
            showZeros={false}
            showClose={false}
            clickEnabled={false}
            messageSize={"1rem"}
            timerSize={"1rem"}
          />
        </Box> */}
        <form onSubmit={handleSignUp} noValidate>
          <Stack direction="column" gap={2}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <NameField
                name={"firstName"}
                value={values.firstName}
                error={errors.firstName}
                handleChange={handleChange}
                label={"First Name"}
              />
              <NameField
                name={"lastName"}
                value={values.lastName}
                error={errors.lastName}
                handleChange={handleChange}
                label={"Last Name"}
              />
            </Box>
            <PhoneField
              phone={values.phone}
              error={errors.phone}
              handleChange={handleChange}
            />

            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.termsAccepted}
                    onChange={handleChange}
                    name="termsAccepted"
                  />
                }
                label={
                  <span>
                    I accept the{" "}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </span>
                }
              />
              {errors.termsAccepted && (
                <div>
                  <Typography variant="caption" color="error">
                    {errors.termsAccepted}
                  </Typography>
                </div>
              )}
            </Box>

            <Button
              variant={
                values.email &&
                values.password &&
                values.firstName &&
                values.lastName &&
                values.termsAccepted &&
                values.phone
                  ? "contained"
                  : "contained" //"outlined"
              }
              type="submit"
              size="large"
              fullWidth
              disabled={loading}
              sx={{
                height: {
                  xs: "56px",
                  textTransform: "none",
                  position: "relative", // Needed for the overlay
                  overflow: "hidden", // Ensures the effect stays within the button boundaries
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: "-150%", // Start off the button
                    width: "100%", // Make it wide enough to cover the button
                    height: "100%",
                    background:
                      "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
                    transform: "skewX(-20deg)", // Slight skew to angle the wipe
                    animation: "wipe 5s infinite", // Smooth, infinite animation
                  },
                  "@keyframes wipe": {
                    "0%": { left: "-300%" },
                    "100%": { left: "300%" }, // Move it fully across
                  },
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Stack>
        </form>
      </Card>
    </Box>
  );
};

export default MemberSignUp;
