import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import GoogleMapsDrawingV2 from "../../../components/maps/GoogleMapsDrawingV2";
import { auth, db } from "../../../firebase";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { updateEmployeeLocation } from "../../../services/employeeServices";
import { generateGeohashesInPolygon } from "../../../services/locationServices";

const validateBounds = (values) => {
  let errors = {};

  if (!values.bounds || values.bounds.length < 3)
    errors.bounds = "You must draw your service map.";

  return errors;
};

const handleBoundsChange = async (newBounds) => {
  // setLoading(true);
  // setBounds(newBounds);

  try {
    await updateEmployeeLocation({ bounds: newBounds });
  } catch (error) {
    console.error("Error updating bounds", error);
  }
  // finally {
  //   setLoading(false);
  // }
};

const BoundsView = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  buttonText = "Continue",
}) => {
  const [loading, setLoading] = useState(false);
  const { data } = useEmployee();

  console.log("data: ", data);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    bounds: data?.employee?.location?.bounds || [],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setValues({
      bounds: data?.employee?.location?.bounds || [],
    });
  }, [data?.employee?.location?.bounds]);

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validateBounds(values);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      if (data) {
        const geohash5Arr = generateGeohashesInPolygon(
          values.bounds
        );

        const userDoc = doc(db, "employees", auth.currentUser.uid);

        await updateDoc(userDoc, {
          "location.bounds": values.bounds, // Dot notation for nested property update
          geohash5Arr,
          "flags.pendingServiceUpdate": true,
          "flags.hasBoundsUpdate": true,
        });

        handleBoundsChange(values.bounds);
      }

      handleNext(values.bounds);
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          py: { xs: 0, sm: 2 },
          px: 2,
          minHeight: "300px",
          alignItems: "flex-start",
        }}
      >
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Box
          sx={{
            height: "auto",
            width: { xs: "100vw", sm: "100%" },
            mx: { xs: -2, sm: 0 },
            boxSizing: "content-box",
          }}
        >
          <GoogleMapsDrawingV2
            onBoundsChange={(bounds) => {
              setValues((prevValues) => ({
                ...prevValues,
                bounds,
              }));
            }}
            employeeGeohash={data?.employee?.location?.geohash}
            initialBounds={data?.employee?.location?.bounds || []}
            initialCenter={{
              lat: data?.employee?.location?.lat || "",
              lng: data?.employee?.location?.lng || "",
            }}
          />
        </Box>

        <FormHelperText
          error={errors.bounds}
          sx={{ boxSizing: "border-box", mt: 0, height: "20px" }}
        >
          {errors.bounds
            ? errors.bounds
            : "Your service area will be visible to the public."}{" "}
        </FormHelperText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>{buttonText}</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default BoundsView;
