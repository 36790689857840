import { Box } from "@mui/material";
import React from "react";

import checkListPng from "../../../assets/check_list_png.png";
import piggyBankPng from "../../../assets/piggy_bank_png.png";
import thumbsUpPng from "../../../assets/thumbs_up_png.png";
import BasicCard from "../../../components/cards/BasicCard";

const contentSections = [
  {
    image: checkListPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(150,100%,70%,0.75)",
    title: "Track Your To-Do’s",
    description: `Over the weeks, our members keep track of the tasks they either don’t want to do, 
      can’t do anymore, or want an extra hand with. When you're ready for 1-2 hours of help, 
      schedule over the phone or online and we'll mark your to-do list complete.`,
  },
  {
    image: thumbsUpPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(191,100%,70%,0.75)",
    title: "Many Tasks, One Visit",
    description: `We are loved for our versatility! Within a single visit, we might fix a problem 
    on your computer, move heavy boxes in the garage, scrub your kitchen cabinets, and weed your flowerbeds, 
    all while getting to know our incredible helpers.`,
  },
  {
    image: piggyBankPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(210,100%,70%,0.75)",
    title: "Help For Under $80",
    description: `Our members book 1 to 2 hours on average at our flat $40 per hour rate. 
      There's no monthly fees and you can
      book more or less depending on your budget and needs. Enjoy help on demand for all your to-do's!`,
  },
];

const BenefitsSection = () => {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        mx: "auto",
        textAlign: "left",
        maxWidth: "700px",
      }}
    >
      {contentSections.map((section, index) => (
        <BasicCard
          key={index}
          section={section}
          index={index}
          pageName={"/home"}
        />
      ))}
    </Box>
  );
};

export default BenefitsSection;
